import React, {useEffect, useState} from 'react'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import ScheduleEmailFormView from '../ScheduleEmail/ScheduleEmail.form'
import EmailConfigFormView from '../EmailConfig/EmailConfig.form'
import { ExpansionList } from '@react-md/expansion-panel'
import {  toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import ErrorService from './../../services/errorService'
import EmailConfigService from '../EmailConfig/EmailConfig.service'
import service from '../ScheduleEmail/ScheduleEmail.service'
import ScheduleEmailService from '../ScheduleEmail/ScheduleEmail.service'
import DaftarSpt from './../DaftarSpt/DaftarSpt.service'
import AppMode from '../../AppMode'

const Email = ({authStore, envStore, match }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({})
  const [emailConfigData, setEmailConfigData] = useState({})
  const [scheduleEmailConfig, setScheduleEmailConfig] = useState({})

  return (
    <FormWrapper
      actions={[
      ]}
      style={{
          maxWidth:'100%'
      }}
      baseId="mod-form-sample"
      title={t.translate('menu.email')}
      hintShowIcon={false}
      definitions={[
        {
            render: (
                <ExpansionList style={{ width: '100%' }}>
                    <EmailConfigFormView match={match} setEmailConfigData={setEmailConfigData} style={{maxWidth: '100%'}}></EmailConfigFormView>
                    <ScheduleEmailFormView match={match} setScheduleEmailConfig={setScheduleEmailConfig}style={{maxWidth: '100%'}}></ScheduleEmailFormView>
                </ExpansionList>
            )
        }
      ]}
      onSubmit={async(formData, callback) => {
        // Setting Schedule
        try {
          var formData = Object.assign({}, scheduleEmailConfig)
          var authStoreJson = toJS(authStore)
          var lastModifiedBy = authStoreJson.user.username
          formData.lastModifiedBy = lastModifiedBy
          let settingEmail = await EmailConfigService.getByCompany(match.params.companyId)
          console.log(settingEmail, "TELL ME")
          settingEmail.data.settingEmailId = settingEmail.data.id
          settingEmail.data.login = authStoreJson.user.username
          settingEmail.data.userId =  authStoreJson.user.id
          delete settingEmail.data.id
          formData = { ...formData, ...settingEmail.data}

          var organization = await DaftarSpt.getOrganization({
            page: 0,
            size: 2000
          })
          formData.organizations = organization.data
          formData.organizations.map((d)=> {
            d.id = match.params.companyId
          })

          // let organizations = UtilsService.getOrganizations(toJS(authStore))
          // organizations.map((d)=> {
          //   delete d.permission
          // })
          // formData.organizations = organizations
          var res = await ScheduleEmailService.putByCompany(formData.id, formData)
          callback(t.translate('words.emailConfigSave'), false, false, false)   
        } catch(e){
          ErrorService(e)
        }
        // Setting Email
        try {
          var formData = Object.assign({}, emailConfigData)
          var authStoreJson = toJS(authStore)
          formData.port = parseInt(formData.port)
          formData.proxyPort = parseInt(formData.proxyPort)

          if(AppMode.onPremise){
            formData.settingEmailId = formData.id
            formData.login = authStoreJson.user.username
            formData.userId = authStoreJson.user.id
            var scheduleEmail = await ScheduleEmailService.getByCompany(match.params.companyId, authStoreJson.user.username)
            var scheduleEmail = await EmailConfigService.getByCompanyId(match.params.companyId)
            scheduleEmail.data.scheduleEmailId = scheduleEmail.data.id
            delete scheduleEmail.data.id
            formData = Object.assign({ ...scheduleEmail.data, ...formData })
            formData.settingEmail = emailConfigData

            var organization = await DaftarSpt.getOrganization({
              page: 0,
              size: 2000
            })
            formData.organizations = organization.data
            formData.organizations.map((d)=> {
              d.id = match.params.companyId
            })

            let organizations = UtilsService.getOrganizations(toJS(authStore))
            organizations.map((d) => {
              delete d.permission
            })
            formData.organizations = organizations
          }
          try{
            var res = null
            if(AppMode.onPremise){
              res = await EmailConfigService.updateEmail(formData)
            }else{
              res = await EmailConfigService.updateByCompanyId(formData)
            }
            callback(t.translate('words.emailScheduleSave'), false, false, false)
          }catch(e){
            ErrorService(e)
          }
        } catch (e) {
          ErrorService(e)
        }
        callback("", false, false, false)
      }}
    />
  )
}

export default inject('authStore','envStore')(observer(Email))
