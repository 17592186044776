import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpUserOrg.service'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import { ListItem, List, Button, DialogTitle, DialogHeader,DialogContent,Dialog} from 'react-md'
import errorService from './../../services/errorService'
import iziToast from 'izitoast'
import fileSaver from 'file-saver'
import { inject, observer } from 'mobx-react'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import moment from 'moment'
import AppMode from '../../AppMode'

const OpUserOrg = ({
  className       = '',
  showCommandbar  = true,
  modalStore,
  history,
  match

}) => {
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})
  const [data, setData] = useState([])
  const [exportCsvOrg,setExportCsvOrg] = useState(false)
  
  const baseId = 'mod-op-userOrganisasi'
  const basePath = 'userOrganisasi'

  async function initData(){}

  useEffect(async () => {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, []) 

  async function hapusExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.bulkDelete([di.id])
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
    } catch(e) {
        errorService(e)
    }
  }

  async function hapus(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        hapusExc(item)
        callback()
      }
    })
  }

  async function deleteByIdExc(item) {
    try {
      let res = await service.deleteById(item.id)
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function deleteById(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteByIdExc(item)
        callback()
      }
    })
  }

  async function exportCsvExc(item) {
    try {
        var proses = t.translate('words.exportCsv')
        var selected = JSON.parse(TableWrapper.getSelected(baseId))
        var selectedData = []
        selected.map((d)=> selectedData.push(data[d].id))
        setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
        var errors = []
        bluebird.mapSeries([1], async (di, di2)=> {
          setTimeout(async ()=> {
            try {
              let proccedThis = await service.exportCsv(selectedData)
              const blob = new Blob([proccedThis.data], {type: proccedThis.type})
              fileSaver.saveAs(blob, proccedThis.headers.filename);
            } catch(e){
              errors.push(await errorServiceGet(e, di, di2))
            }
            setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
            if((di2+1) == selectedData.length){
              setPloading({ loading: false, message: `` })
              if(errors.length == 0){
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                })
              } else {
                modalStore.showInfo({
                  title: '',
                  children: (
                    <List>
                      {errors.map((er)=> {
                        return <ListItem primaryText={''} secondaryText={er}></ListItem>
                      })}
                    </List>
                  )  
                })
              }
              try{
                var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                if(checkboxRoot.checked){
                  if(checkboxRoot.getAttribute('aria-checked')){
                    checkboxRoot.click()
                    checkboxRoot.click()
                  } else {
                    checkboxRoot.click()
                  }
                }
                document.getElementsByClassName('mdi-reload')[0].click()
              }catch(e){}
            }
          }, 1000 * di2)
        })
    } catch(e) {
        errorService(e)
    }
  }

  async function exportCsv(item) {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    var selectedData = []
    selected.map((d) => selectedData.push(data[d]))
    
    if (selectedData.length >= 1) {
      modalStore.showConfirm({
        title : t.translate('words.exportCsv'),
        children : t.translate('words.confirmexportCsv'),
        onSubmit : (callback) => {
         exportCsvExc(item)
          callback()
        }
      })
    } else {
      setExportCsvOrg(true)
    }
    
  }

  return (
    <>
      <Dialog
        visible={exportCsvOrg}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setExportCsvOrg(false)
        }}
        style={{
          width: 800,
          // height: AppMode.onPremise ? 400 : 400
        }}
      >
      <DialogHeader>
        <DialogTitle>{t.translate('words.exportOrgUserCsv')}</DialogTitle>
      </DialogHeader>
      <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <DataForm
          baseId={`${baseId}-export-csv-org-user`}
          defaultData={[]}
          submitLabel={t.translate('words.kirim')}
          definitions={[
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.npwp`),
              key: 'npwp.contains',
              mask: '##.###.###.#-###.###',
              maskChar: '_',
              maskValue: 'string',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.firstName`),
              key: 'userFirstName.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.lastName`),
              key: 'userLastName.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.login`),
              key: 'userLogin.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.email`),
              key: 'userEmail.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
          ]}
          additionalAction={[
            {
              show: true,
              render: () => (
                <Button style={{ marginRight: 15 }} onClick={() => setExportCsvOrg(false)}>{t.translate('words.close')}</Button>
              )
            }
          ]}
          onSubmit={async (values, callback) => {
            try {
              var companyId = null
              var user = JSON.parse(localStorage.getItem('user'))
              console.log(user, "ini apa ?")
              // if(user.authorities[0].name == "ROLE_EPPT_ADMIN"){
              //   getPage = await service.get(params)
              // }
              if(window.location.href.indexOf('open') != -1){
                companyId = match.params.companyId
              }
              if(user.authorities[0].name == "ROLE_EPPT_OWNER"){
                companyId = user.company.id
              }

              try{
                var res = await service.exportCsvBlob(values)
                const blob = new Blob([res.data], {type: 'CSV'})
                var filename = res.headers.filename
                setExportCsvOrg(false)

                iziToast.success({
                  message: `${t.translate('words.exportOrgUserCsv')} ${t.translate('words.prosesDone')}`
                })

                fileSaver.saveAs(blob, filename);
                
              }catch(e){
                errorService(e)
                setExportCsvOrg(false)

              }
              
            } catch (e) {
              errorService(e)
              iziToast.error({
                message: `${t.translate('words.exportOrgUserCsv')} ${t.translate('words.prosesFail')}`
              })
              callback('', false, false, false)
            }
          }}
        >
        </DataForm>
      </DialogContent>
     </Dialog>
      <TableWrapper useFilter={false}
        selectable = {true}
        baseId = {baseId}
        title = {t.translate(`modules.${basePath}.title`)}
        className = {className}
        defaultData={[]}
        defaultSortBy="id"
        sideHeader={
          <DataForm 
            baseId={`${baseId}-search`}
            defaultData={search}
            submitIconClassName = 'mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button style={{ marginRight: 15 }} onClick={()=> {
                    setSearch({})
                    var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                    setTimeout(()=> {
                      document.getElementsByClassName('mdi-reload')[0].click()
                    }, 1000)
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback)=> {
              values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
              setTimeout(()=> {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false, false)
              }, 1000)
            }}
            definitions={[
              {
                render: (
                  <div className="mpk-data-filter">
                    <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                      <p>{t.translate(`words.pencarian`)}</p>
                    </div>
                  </div>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: t.translate('words.npwp'),
                key: 'npwp.contains',
                type: 'text',
                mask: '##.###.###.#-###.###',
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.firstName`),
                key: 'userFirstName.contains',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.lastName`),
                key: 'userLastName.contains',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.signIn`),
                key: 'userLogin.contains',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.email`),
                key: 'userEmail.contains',
                type: 'text'
              },
            ]}
          />
        }
        columns={[
          {
            label: t.translate('words.npwp'),
            searchable: true,
            sortable : true,
            key:'npwp',
            render: item => item.npwp
          },
          {
            label: t.translate('words.signIn'),
            searchable: true,
            sortable : true,
            key: 'user',
            type: TableWrapper.dataTypes.STRING,
            render: item => item.user.login
          },
          {
            label: t.translate('words.firstName'),
            searchable: true,
            sortable : true,
            key:'user',
            render: item => item.user.firstName
          },
          {
            label: t.translate('words.lastName'),
            searchable: true,
            sortable : true,
            key: 'user',
            type: TableWrapper.dataTypes.STRING,
            render: item => item.user.lastName
          },
          {
            label: t.translate('words.email'),
            searchable: true,
            sortable : true,
            key: 'user',
            type: TableWrapper.dataTypes.STRING,
            render: item => item.user.email
          },
          {
            label: t.translate('words.role'),
            searchable: true,
            sortable : true,
            key: 'user',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              try{
                return item.eunifikasiRoles
              } catch(e){
                return "-"
              }
            }
          },
          {
            label: t.translate('words.createdBy'),
            searchable: true,
            sortable : true,
            key: 'createdBy',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.createdBy)
          },
          {
            label: t.translate('words.createdDate'),
            searchable: true,
            sortable : true,
            key: 'createdDate',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.createdDate)
          },
          {
            label: t.translate('words.lastModifiedBy'),
            searchable: true,
            sortable : true,
            key: 'lastModifiedBy',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.lastModifiedBy)
          },
          {
            label: t.translate('words.lastModifiedDate'),
            searchable: true,
            sortable : true,
            key: 'lastModifiedDate',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.lastModifiedDate)
          },
        ]}
        actions={[
          new TableWrapper.action(`${t.translate('words.Add')}`, 'mdi mdi-plus', () => history.push(`${basePath}/new`), true),
          new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { hapus(item)}, true),
          new TableWrapper.action(`${t.translate('words.exportCsv')}`, 'mdi mdi-file-excel', (item) => {exportCsv(item)}, true),
        ]}
        itemActions={[
          new TableWrapper.action(`${t.translate('words.moreInfo')}`, 'mdi mdi-bell', (item) => {
            localStorage.setItem('userOrg', JSON.stringify(item))
            history.push(`${basePath}/${item.id}`)}),
          new TableWrapper.action('Delete', 'mdi mdi-delete', (item) => {deleteById(item)}, true)
        ]}
        setPage={search.page} onFetchData={ (params)=> {
          return (
            new Promise(async (resolve, reject)=> {
              params = {...params,...search}
              try {
                await initData()
                let user = JSON.parse(localStorage.getItem('user'))
                var companyId = user.company.id
                let getPage = null
                getPage = await service.findAll(params)
                delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                setData(getPage.data)
                resolve(getPage)
              } catch(e){
                resolve({ data: [] })
                errorService(e)
              }
            })
          )
        }}
        showCommandbar={showCommandbar}
        showFilterPeriod={true}
      />
    </>
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpUserOrg))

