import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import _ from 'lodash'
import service from './LogEmail.service'
import { Chip, ListItem, List, Button, Dialog, DialogHeader, DialogContent, DialogTitle } from 'react-md'
import ErrorService from '../../services/errorService'
import { TableCheckbox } from 'react-md'
import { inject, observer } from 'mobx-react'
import iziToast from 'izitoast'
import LoadingOverlay from 'react-loading-overlay'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import AppMode from '../../AppMode'
import SptService from '../DaftarSpt/DaftarSpt.service'
import { toJS } from 'mobx'
import UtilsService from './../../services/utilsService'
import { param } from 'react-dom-factories'

const LogEmail = ({
  modalStore,
  className       = '',
  showCommandbar  = true,
  history,
  match,
  authStore
}) => {

  const basePath = `log-email`
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [data, setData] = useState([])
  const [npwp, setNpwp] = useState([])
  let [search, setSearch] = useState({})
  const baseId = 'mod-log-email'
  const [organization, setOrganization] = useState({})
  var insideSpt = false
  const [exportDialog, setExportDialog] = useState(false);
  const [exportDialogResend, setExportDialogResend] = useState(false);
  const [fetchParam, setFetchParam] = useState([])

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }

    if(AppMode.onPremise){
      getOrganizationsOp()
    } else {
      getOrganizations()
    }

  }, [])

  const getOrganizations = async (values)=> {
    var params = {
      page: 0,
      size: 200
    }
    if(isNaN(values)){
      params['field'] = 'name'
      params['query'] = values
    } else {
      params['field'] = 'npwp'
      params['query'] = values
    }
    let organization = await service.getOrganization(params)
    var getAll = organization.data
    var organizationsFilter = []
    try {
      getAll.map((og)=> {
        og.label = og.npwp + " - " + og.name
        if(og.certExists) organizationsFilter.push({
          value: og,
          label: og.label
        })
      })
    } catch(e){
      organization.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        organizationsFilter.push({
          value: d,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
    return organizationsFilter
  }

  const getOrganizationsOp = async (values)=> {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if(isNaN(values)){
        params['name.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await SptService.getOrganizationOp(params)
      res.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        d.value = d.npwp
      })
      setOrganization(res.data)
      return res.data
    } catch(e){
      ErrorService(e)
      return []
    }
  }

  var exportData = () => {
    setExportDialog(true)
  }

  var exportSelected = async (dataMod) => {
    setExportDialog(false)
    var exportData = []
    if(dataMod){
      exportData = dataMod
    } else {
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      exportData = selectedData
      if(selected.length == 0){
        exportData = data
      }
    }
    try {
      var ids = []
      exportData.map((d)=> {
        ids.push(d.id)
      })
      var proses = t.translate('words.exportData')
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      let res = await service.exportCurrent(ids)
      setPloading({ loading: false, message: `` })
      iziToast.success({
        title: proses,
        message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
      })
      window.open(res.data.url)
    } catch(e){
      setPloading({ loading: false, message: `` })
      ErrorService(e)
    }
  }

  var exportAll = async () => {
    setExportDialog(false)
    var exportAllExc = false
    var dataSelected = []
    try {
      var cQuery = JSON.parse(localStorage.getItem(`${baseId}-query`))
      var params = fetchParam; params.size = 200;
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })

      // INIT DATA
      if (window.location.href.indexOf('open') != -1) {
        let spt = JSON.parse(localStorage.getItem('spt'))
        params.sptNpwp = spt.npwp
        params.sptYear = spt.year
        params.sptMonth = spt.month
        params.sptRev = spt.rev
        let getPage = await service.getPage(params)
        delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
        dataSelected = getPage.data
        if(parseInt(getPage.headers['x-total-count']) > 100){
          exportAllExc = true
        } else {
          exportAllExc = false
        }
      } else {
        let getPage = await service.get(params)
        delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
        dataSelected = getPage.data
        if(parseInt(getPage.headers['x-total-count']) > 100){
          exportAllExc = true
        } else {
          exportAllExc = false
        }
      }
      // INIT DATA
      if(exportAllExc){
        var proses = t.translate('words.exportData')
        
        let res = await service.exportAll(fetchParam)
        setPloading({ loading: false, message: `` })
        iziToast.success({
          title: proses,
          message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDoneLogExport')}`
        })
      } else {
        exportSelected(dataSelected)
      }
    } catch(e){
      setPloading({ loading: false, message: `` })
      ErrorService(e)
    }
  }

  // Tambahan Uci
  async function resendExc(item) {
    try {
      var proses = t.translate('words.kirimUlangEmail')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      var selectedDn = [];
      var selectedLn = [];
      selected.map((d) => {
        selectedData.push(data[d].id)
        if (data[d].type == "DN") selectedDn.push(data[d].id);
        if (data[d].type == "LN") selectedLn.push(data[d].id);
      })
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      if (selectedData.length > 0) {
        bluebird.mapSeries([1], async (di, di2) => {
          setTimeout(async () => {
            try {
              if (selectedDn.length > 0) var resDn = await service.postResendDn(selectedDn);
              if (selectedLn.length > 0) var resLn = await service.postResendLn(selectedLn);
              // let proccedThis = await service.postResend(selectedData)
            } catch (e) {
              errors.push(await errorServiceGet(e, di, di2))
            }
            setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
            if ((di2 + 1) == selectedData.length) {
              setPloading({ loading: false, message: `` })
              if (errors.length == 0) {
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                })
              } else {
                modalStore.showInfo({
                  title: '',
                  children: (
                    <List>
                      {errors.map((er) => {
                        return <ListItem primaryText={''} secondaryText={er}></ListItem>
                      })}
                    </List>
                  )
                })
              }
              try {
                var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                if (checkboxRoot.checked) {
                  if (checkboxRoot.getAttribute('aria-checked')) {
                    checkboxRoot.click()
                    checkboxRoot.click()
                  } else {
                    checkboxRoot.click()
                  }
                }
                document.getElementsByClassName('mdi-reload')[0].click()
              } catch (e) { }
            }
          }, 1000 * di2)
        })
      } else {
        setPloading({ loading: false, message: `` })
      }
    } catch (e) {
      ErrorService(e)
    }
  }
  async function resend(item) {
    modalStore.showConfirm({
      title: t.translate('words.kirimUlangEmail'),
      children: t.translate('words.confirmResendEmail'),
      onSubmit: (callback) => {
        resendExc(item)
        callback()
      }
    })
  }
  // Tambahan Uci

  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
        >
      </LoadingOverlay>
      <Dialog
        visible={exportDialog}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setExportDialog(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.exportData')}</DialogTitle>
        </DialogHeader>
        <DialogContent>
          <Button themeType="outline" theme="primary" style={{ marginRight: '12px' }} onClick={() => exportSelected()}>{t.translate('words.exportDataSelected')}</Button>
          <Button themeType="outline" onClick={() => exportAll()}>{t.translate('words.exportDataAll')}</Button>
        </DialogContent>
      </Dialog>
      <Dialog
        visible={exportDialogResend}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setExportDialogResend(false)
        }}
        style={{
          width: 800,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.exportData')}</DialogTitle>
        </DialogHeader>
        <DialogContent>
          <DataForm
            baseId={`${baseId}-search`}
            defaultData={[]}
            submitIconClassName=' '
            submitLabel={`${t.translate('words.resend')}`}
            onChange={(formData, key, value)=> {
              if(key == 'type'){
                var type = value
                console.log(type)
              }
            }}
            onSubmit={(values, callback) => {
              // resend(values)
              setExportDialogResend(false)
            }}
            definitions={[
              {
                inputType : inputTypes.SELECT,
                label : t.translate('words.type'),
                key : 'type',
                validation: 'required',
                options: [
                  { label: 'Dalam Negeri', value: 'DN' },
                  { label: 'Luar Negeri', value: 'LN' },
                ]
              },
            ]}
          />
          {/* <Button themeType="outline" theme="primary" style={{ marginRight: '12px' }} onClick={() => exportSelected()}>{t.translate('words.exportDataSelected')}</Button>
          <Button themeType="outline" onClick={() => exportAll()}>{t.translate('words.exportDataAll')}</Button> */}
        </DialogContent>
      </Dialog>
      <TableWrapper useFilter={false}
        baseId={baseId}
        title={t.translate('modules.logDataEmail.title')}
        className={className}
        selectable={true}
        defaultData={[]}
        defaultSortBy="createdDate"
        setPage={search.page} onFetchData={(params)=> {
          return (
            new Promise(async (resolve, reject)=> {
              params = {...params,...search}
              try {
                if(AppMode.onPremise){
                  if(params['sptNpwp.in'] && params['sptNpwp.in'].length > 0){
                    var stringIn = ""
                    params['sptNpwp.in'].map((d)=> {
                      stringIn += d.value.npwp + ","
                    })
                    params['sptNpwp.in'] = stringIn
                  }  else {
                    delete params['sptNpwp.in']
                  }
                } else {
                  if(params['npwpProfiles.in'] && params['npwpProfiles.in'].length > 0){
                    var stringIn = ""
                    params['npwpProfiles.in'].map((d)=> {
                      stringIn += d.value.npwp + ","
                    })
                    params['npwpProfile.in'] = stringIn
                  }  else {
                    delete params['npwpProfile.in']
                  }
                }
              } catch(e){}
              try{
                params = {...params,...search}
                var getPage;
                if(window.location.href.indexOf('open') != -1){
                  var spt = JSON.parse(localStorage.getItem('spt'))
                  // params.sptNpwp = spt.npwp
                  // params.sptYear = spt.year
                  // params.sptMonth= spt.month
                  // params.sptRev  = spt.rev
                  delete params['npwpProfiles.in']
                  getPage = await service.getBySpt(params, spt.id)
                } else {
                  delete params['npwpProfiles.in']
                  getPage = await service.get(params)
                }
                setFetchParam(params)
                setData(getPage.data)
                delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                resolve(getPage)
              } catch(e){
                setFetchParam(params)
                resolve()
                ErrorService(e)
              }
            })
          )
        }}
        sideHeader={
          <DataForm 
            baseId={`${baseId}-search`}
            defaultData={search}
            submitIconClassName = 'mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button style={{ marginRight: 15 }} onClick={()=> {
                    setSearch({})
                    var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                    setTimeout(()=> {
                      document.getElementsByClassName('mdi-reload')[0].click()
                    }, 1000)
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback)=> {
              var formData = Object.assign({}, values)
              if(formData['createdDate.greaterOrEqualThan']) formData['createdDate.greaterOrEqualThan'] = new Date(formData['createdDate.greaterOrEqualThan'])
              if(formData['createdDate.lessOrEqualThan']) formData['createdDate.lessOrEqualThan'] = new Date(formData['createdDate.lessOrEqualThan'])
              if(AppMode.onPremise){
                if(formData['sptNpwp.in'] && formData['sptNpwp.in'].length > 0){
                  var stringIn = ""
                  formData['sptNpwp.in'].map((d)=> {
                    stringIn += d.value.npwp + ","
                  })
                  formData['sptNpwp.in'] = stringIn
                }  else {
                  delete values['sptNpwp.in']
                }
              } else {
                if(formData['npwpProfiles.in'] && formData['npwpProfiles.in'].length > 0){
                  var stringIn = ""
                  formData['npwpProfiles.in'].map((d)=> {
                    stringIn += d.value.npwp + ","
                  })
                  formData['npwpProfile.in'] = stringIn
                }  else {
                  delete values['npwpProfile.in']
                }
                if(formData.allNpwps){
                  var allNpwpString = ""
                  if(organization && organization.length > 0){
                    organization.map((d)=> {
                      allNpwpString += d.value.npwp + ","
                    })
                  }
                  formData['npwpProfile.in'] = allNpwpString
                  delete formData.allNpwps
                }
              }
              
              setSearch(formData);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
              setTimeout(()=> {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false)
              }, 1000)
            }}
            definitions={[
              {
                render: (
                  <div className="mpk-data-filter">
                    <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                      <p>{t.translate(`words.pencarian`)}</p>
                    </div>
                  </div>
                )
              },
              // {
              //   render: (
              //     <div style={{ width: '100%', marginBottom: '12px' }}>
              //       {!AppMode.onPremise &&
              //         <Button onClick={() => exportData()} style={{ width: '100%' }} theme="primary" themeType="contained">{t.translate(`words.exportData`)}</Button>
              //       }
              //     </div>
              //   )
              // },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.allNpwps`),
                key: 'allNpwps',
                type: 'text',
                show : AppMode.onPremise ? false : true
              },
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.npwpPemotong`),
                key: AppMode.onPremise ? 'sptNpwp.in' : 'npwpProfiles.in',
                labelKey: 'label', 
                valueKey: 'label',
                data: organization,
                options: organization,
                async: true,
                defaultOptions: organization,
                isMulti: true,
                loadOptions: async (inputValues)=> {
                  if(AppMode.onPremise){
                    if(inputValues.length % 2){
                      var options = await getOrganizationsOp(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  } else {
                    if(inputValues.length % 2){
                      var options = await getOrganizations(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  }
                },
                show: !insideSpt
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.identityLt`),
                key: 'identity.contains',
                type: 'text',
                width: '100%'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.email`),
                key: 'email.contains',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.sendBy`),
                key: 'sendBy.contains',
                type: 'text'
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.type`),
                key: 'type.equals',
                type: 'text',
                options: [
                    { label: 'DN', value: 'DN' },
                    { label: 'LN', value: 'LN' },
                ]
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.month`),
                key: 'sptMonth.equals',
                type: 'text',
                options: [
                    { label: '1', value: '1' },
                    { label: '2', value: '2' },
                    { label: '3', value: '3' },
                    { label: '4', value: '4' },
                    { label: '5', value: '5' },
                    { label: '6', value: '6' },
                    { label: '7', value: '7' },
                    { label: '8', value: '8' },
                    { label: '9', value: '9' },
                    { label: '10', value: '10' },
                    { label: '11', value: '11' },
                    { label: '12', value: '12' },
                ]
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.year`),
                key: 'sptYear.equals',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.rev`),
                key: 'sptRev.equals',
                type: 'text'
              },
              {
                inputType: inputTypes.DATEPICKER,
                label: t.translate(`words.tanggal1`),
                key: 'createdDate.greaterOrEqualThan',
                type: 'date'
              },
              {
                inputType: inputTypes.DATEPICKER,
                label: t.translate(`words.tanggal2`),
                key: 'createdDate.lessOrEqualThan',
                type: 'date'
              },
              // {
              //   inputType: inputTypes.INPUT,
              //   label: t.translate(`words.createdBy`),
              //   key: 'createdBy.contains',
              //   type: 'text'
              // },
            ]}
          />
        }
        columns={[
          {
            label: t.translate('words.status'),
            searchable: true,
            sortable : true,
            key: 'status',
            type: TableWrapper.dataTypes.STRING,
            render: item => {
              if(item.success){
                return <Chip style={{ background: "#8BC34A", color: 'white' }}>{(item.success+"").toUpperCase()}</Chip>
              } else {
                return <Chip style={{ background: "#F44336", color: 'white' }}>{(item.success+"").toUpperCase()}</Chip>
              } 
            }
          },
          // {
          //   label: t.translate('words.type'),
          //   searchable: true,
          //   sortable : true,
          //   key: 'type',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => {
          //     if(item.type){
          //       return <Chip style={{ background: "#8BC34A", color: 'white' }}>{(item.type+"").toUpperCase()}</Chip>
          //     } else {
          //       return (("-"))
          //     } 
          //   }
          // },
          {
            label: t.translate('words.identity'),
            searchable: true,
            sortable : true,
            key: 'identity',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.identity)
          },
          {
            label: t.translate('words.email'),
            searchable: true,
            sortable : true,
            key: 'email',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.email)
          },
          {
            label: t.translate('words.message'),
            searchable: true,
            sortable : true,
            key: 'message',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.message)
          },
          {
            label: t.translate('words.createdBy'),
            searchable: true,
            sortable : true,
            key: 'createdBy',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.createdBy)
          },
          {
            label: t.translate('words.createdDate'),
            searchable: true,
            sortable : true,
            key: 'createdDate',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.createdDate)
          },
          {
            label: t.translate('words.lastModifiedDate'),
            searchable: true,
            sortable : true,
            key: 'lastModifiedDate',
            type: TableWrapper.dataTypes.STRING,
            render: item => (item.lastModifiedDate)
          },
          // {
          //   label: t.translate('words.no'),
          //   searchable: true,
          //   sortable : true,
          //   key: 'no',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (item.no)
          // },
          // {
          //   label: t.translate('words.type'),
          //   searchable: true,
          //   sortable : true,
          //   key: 'type',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (item.type)
          // },
          // {
          //   label: t.translate('words.sptNpwp'),
          //   searchable: true,
          //   sortable : true,
          //   key: 'sptNpwp',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (item.sptNpwp)
          // },
          // {
          //   label: t.translate('words.sptMonth'),
          //   searchable: true,
          //   sortable : true,
          //   key: 'sptMonth',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (item.sptMonth)
          // },
          // {
          //   label: t.translate('words.sptYear'),
          //   searchable: true,
          //   sortable : true,
          //   key: 'sptYear',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (item.sptYear)
          // },
          // {
          //   label: t.translate('words.sptRev'),
          //   searchable: true,
          //   sortable : true,
          //   key: 'sptRev',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (item.sptRev)
          // },
          // {
          //   label: t.translate('words.sendBy'),
          //   searchable: true,
          //   sortable : true,
          //   key: 'sendBy',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (item.sendBy)
          // },
          // {
          //   label: t.translate('words.sendAt'),
          //   searchable: true,
          //   sortable : true,
          //   key: 'sendAt',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (item.sendAt)
          // },
          // {
          //   label: t.translate('words.deliveredAt'),
          //   searchable: true,
          //   sortable : true,
          //   key: 'deliveredAt',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (item.deliveredAt)
          // },
          // {
          //   label: t.translate('words.sentMessage'),
          //   searchable: true,
          //   sortable : true,
          //   key: 'sentMessage',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (item.sentMessage)
          // },
          // {
          //   label: t.translate('words.reportMessage'),
          //   searchable: true,
          //   sortable : true,
          //   key: 'reportMessage',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (item.reportMessage)
          // },
          // {
          //   label: t.translate('words.lastModifiedBy'),
          //   searchable: true,
          //   sortable : true,
          //   key: 'lastModifiedBy',
          //   type: TableWrapper.dataTypes.STRING,
          //   render: item => (item.lastModifiedBy)
          // },
        ]}
        actions={[
          //new TableWrapper.action(`${t.translate('words.kirimUlangEmail')}`, 'mdi mdi-email-send', (item) => { resend(item) }, true),
          new TableWrapper.action(`${t.translate('words.kirimUlangEmail')}`, 'mdi mdi-email-send', (item) => { resend(item) }, !AppMode.onPremise? true : false),
          new TableWrapper.action(`${t.translate('words.exportData')}`, 'mdi mdi-file-export', (item) => { exportData(item)}, true),
        ]}
        itemActions={[
          new TableWrapper.action(`${t.translate('words.moreInfo')}`, 'mdi mdi-information-outline', (item) => {
            // localStorage.setItem('spt', JSON.stringify(item))
            history.push(`${basePath}/${item.id}/info`)
          })
        ]}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
    </>
  )
}

export default inject('modalStore', 'authStore')(observer(LogEmail))