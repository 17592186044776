import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { TableWrapper, FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import service from './OpOrganization.service'
import {  Dialog, DialogContent, DialogHeader, DialogTitle, Button, TextField, FontIcon, Chip, ListItem, List, FileInput, Card, CardContent, Table, TableCell, TableRow, TableHeader, TableBody } from 'react-md'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import errorService from './../../services/errorService'
import iziToast from 'izitoast'
import fileSaver from 'file-saver';
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import DataForm from '../../libs/react-mpk/components/DataForm'
import AppMode from '../../AppMode'
import _ from 'lodash'

const OpOrganization = ({
  className       = '',
  showCommandbar  = true,
  history         = '',
  modalStore,
  match
}) => {
  const [data, setData] = useState([])
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [search, setSearch] = useState({})
  let [dialog, setDialog] = useState(false)
  let [dialogItem, setDialogItem] = useState({})
  let [uploadSertel, setUploadSertel] = useState(false)
  const [file, setFile] = useState([])
  const [select, setSelect] = useState({})
  const [error, setError] = useState({password : null, file : null});
  const [exportCsv, setExportCsv] = useState(false)
  const [exportCsvOrg, setExportCsvOrg] = useState(false)
  const [exportSummaryOrg, setExportSummaryOrg] = useState(false)
  const baseId = 'mod-op-org'
  const selectedFIle = null
  const basePath = 'organization-op'

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, [])

  async function initData() {}

  const syncOrg = async (item)=> {
    try {
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))
      let res = await service.syncOrg(selectedData);
      iziToast.success({
        title: "Sync",
        message: t.translate("words.syncOrgDone")
      })
    } catch(e){
      errorService(e)
    }
    try{
      var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
      if(checkboxRoot.checked){
        if(checkboxRoot.getAttribute('aria-checked')){
          checkboxRoot.click()
          checkboxRoot.click()
        } else {
          checkboxRoot.click()
        }
      }
      document.getElementsByClassName('mdi-reload')[0].click()
    }catch(e){}
  }

  const addSertel = async (item) => {
    try{
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      setSelect(selectedData)
    }catch(e){}

    if(selectedData.length > 0){
      setDialogItem(item)
      setDialog(true)
    } else {
      iziToast.info({
        title: "Peringatan",
        message: "Tidak ada data yang dipiih, silahkan cek kembali!"
      })
    }
    
  }

  const addOneSertel = async (item) => {
    setDialogItem(item)
    setUploadSertel(true)
  }

  async function deleteBulkExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.bulkDelete([di.id])
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
    errorService(e)
    }
  }

  async function deleteBulk(item) {
    modalStore.showConfirm({
      title : t.translate('words.hapus'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteBulkExc(item)
        callback()
      }
    })
  }

  async function exportCsvBulkOrgExc(item) {
    try {
      var proses = t.translate('words.exportCsv')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.exportCsvBulkOrg(selectedData)
            const blob = new Blob([proccedThis.data], {type: proccedThis.type})
            fileSaver.saveAs(blob, proccedThis.headers.filename);
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function exportCsvBulkOrg(item) {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    var selectedData = []
    selected.map((d) => selectedData.push(data[d]))
    
    if (selectedData.length >= 1) {
      modalStore.showConfirm({
        title : t.translate('words.exportOrgUserCsv'),
        children : t.translate('words.confirmexportCsv'),
        onSubmit : (callback) => {    
          exportCsvBulkOrgExc(item)
          callback()
        }
      })
    } else {
      setExportCsvOrg(true)
    }
  }

  async function exportSummaryExc(item) {
    try {
      var proses = t.translate('words.exportCsv')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.exportSummary(selectedData)
            const blob = new Blob([proccedThis.data], {type: proccedThis.type})
            fileSaver.saveAs(blob, proccedThis.headers.filename);
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function exportSummary(item) {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    var selectedData = []

    selected.map((d) => selectedData.push(data[d]))
    console.log(selectedData, selected)
    if (selectedData.length >= 1) {
      modalStore.showConfirm({
        title : t.translate('words.confirmexportSummary'),
        children : t.translate('words.confirmexportCsv'),
        onSubmit : (callback) => {
          exportSummaryExc(item)
          callback()
        }
      })
    } else {
      setExportSummaryOrg(true)
    }
  }


  async function exportCsvBulkExc(item) {
    try {
      var proses = t.translate('words.exportCsv')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))

      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.exportCsvBulk(selectedData)
            const blob = new Blob([proccedThis.data], {type: proccedThis.type})
            fileSaver.saveAs(blob, proccedThis.headers.filename);
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function exportCsvBulk(item) {
    var selected = JSON.parse(TableWrapper.getSelected(baseId))
    var selectedData = []

    selected.map((d) => selectedData.push(data[d]))
    console.log(selectedData, selected)
    if (selectedData.length >= 1) {
      modalStore.showConfirm({
        title : t.translate('words.exportCsv'),
        children : t.translate('words.confirmexportCsv'),
        onSubmit : (callback) => {
          exportCsvBulkExc(item)
          callback()
        }
      })
    } else {
      setExportCsv(true)
    }
  }

  async function deleteByIdExc(item) {
    try {
      let res = await service.deleteById(item.id)
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function deleteById(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteByIdExc(item)
        callback()
      }
    })
  }

  function multipleFile(event) {
    try{
      setFile(event.target.files)
      var value = event.target.files
      if(value && value.length > 0){
        Object.values(value).map((d)=> {
          console.log(value)
          var name = d.name
          select.map((c, index)=> {
            if(c.npwp == name.split('_')[0]){ 
              c.file = d
              c.filename = d.name
            } else{
              return value
            }
          })
        })
        setSelect([...select])
    }
    }catch(e){

    }
  }
  
  return (
    <>
     <Dialog
        visible={exportCsv}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setExportCsv(false)
        }}
        style={{
          width: 800,
          // height: AppMode.onPremise ? 400 : 400
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.exportCsv')}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={`${baseId}-export-csv`}
            defaultData={[]}
            submitLabel={t.translate('words.kirim')}
            definitions={[
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.name`),
                key: 'name.contains',
                type: 'text',
                show: () => {
                  return AppMode.onPremise ? true : false
                }
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.aliasName`),
                key: 'aliasName.contains',
                type: 'text',
                show: () => {
                  return AppMode.onPremise ? true : false
                }
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.npwp`),
                key: 'npwp.contains',
                type: 'text',
                show: () => {
                  return AppMode.onPremise ? true : false
                }
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.address`),
                key: 'address.contains',
                type: 'text',
                show: () => {
                  return AppMode.onPremise ? true : false
                }
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.city`),
                key: 'city.contains',
                type: 'text',
                show: () => {
                  return AppMode.onPremise ? true : false
                }
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.email`),
                key: 'email.contains',
                type: 'text',
                show: () => {
                  return AppMode.onPremise ? true : false
                }
              },
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={() => setExportCsv(false)}>{t.translate('words.close')}</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              try {
                var companyId = null
                var user = JSON.parse(localStorage.getItem('user'))
                console.log(user, "ini apa ?")
                // if(user.authorities[0].name == "ROLE_EPPT_ADMIN"){
                //   getPage = await service.get(params)
                // }
                if(window.location.href.indexOf('open') != -1){
                  companyId = match.params.companyId
                }
                if(user.authorities[0].name == "ROLE_EPPT_OWNER"){
                  companyId = user.company.id
                }

                try{
                  var res = await service.csvParam(values, companyId)
                  const blob = new Blob([res.data], {type: 'CSV'})
                  var filename = res.headers.filename

                  iziToast.success({
                    message: `${t.translate('words.exportCsv')} ${t.translate('words.prosesDone')}`
                  })

                  fileSaver.saveAs(blob, filename);
                  setExportCsv(false)
                 
                }catch(e){
                  errorService(e)
                  setExportCsv(false)
                }
                
              } catch (e) {
                errorService(e)
                iziToast.error({
                  message: `${t.translate('words.exportCsv')} ${t.translate('words.prosesFail')}`
                })
                callback('', false, false, false)
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
    <Dialog
      visible={exportCsvOrg}
      disableFocusOnMount={() => { }}
      onRequestClose={() => {
        setExportCsvOrg(false)
      }}
      style={{
        width: 800,
        // height: AppMode.onPremise ? 400 : 400
      }}
    >
      <DialogHeader>
        <DialogTitle>{t.translate('words.exportOrgUserCsv')}</DialogTitle>
      </DialogHeader>
      <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <DataForm
          baseId={`${baseId}-export-csv-org-user`}
          defaultData={[]}
          submitLabel={t.translate('words.kirim')}
          definitions={[
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.orgName`),
              key: 'organizationName.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.orgAliasName`),
              key: 'organizationAliasName.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.orgNpwp`),
              key: 'organizationNpwp.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.orgAddress`),
              key: 'organizationAddress.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.orgCity`),
              key: 'organizationCity.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.orgEmail`),
              key: 'organizationEmail.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.loginUser`),
              key: 'userLogin.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.firstName`),
              key: 'userFirstName.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.lastName`),
              key: 'userLastName.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.email`),
              key: 'userEmail.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
          ]}
          additionalAction={[
            {
              show: true,
              render: () => (
                <Button style={{ marginRight: 15 }} onClick={() => setExportSummaryOrg(false)}>{t.translate('words.close')}</Button>
              )
            }
          ]}
          onSubmit={async (values, callback) => {
            try {
              var companyId = null
              var user = JSON.parse(localStorage.getItem('user'))
              console.log(user, "ini apa ?")
              // if(user.authorities[0].name == "ROLE_EPPT_ADMIN"){
              //   getPage = await service.get(params)
              // }
              if(window.location.href.indexOf('open') != -1){
                companyId = match.params.companyId
              }
              if(user.authorities[0].name == "ROLE_EPPT_OWNER"){
                companyId = user.company.id
              }

              try{
                var res = await service.exportCsvBulkParam(values, companyId)
                const blob = new Blob([res.data], {type: 'CSV'})
                var filename = res.headers.filename
                setExportCsvOrg(false)

                iziToast.success({
                  message: `${t.translate('words.exportOrgUserCsv')} ${t.translate('words.prosesDone')}`
                })

                fileSaver.saveAs(blob, filename);
                
              }catch(e){
                errorService(e)
                setExportCsvOrg(false)
              }
              
            } catch (e) {
              errorService(e)
              iziToast.error({
                message: `${t.translate('words.exportOrgUserCsv')} ${t.translate('words.prosesFail')}`
              })
              callback('', false, false, false)
            }
          }}
        >
        </DataForm>
      </DialogContent>
    </Dialog>
    <Dialog
      visible={exportSummaryOrg}
      disableFocusOnMount={() => { }}
      onRequestClose={() => {
        setExportCsvOrg(false)
      }}
      style={{
        width: 800,
        // height: AppMode.onPremise ? 400 : 400
      }}
    >
      <DialogHeader>
        <DialogTitle>{t.translate('words.exportOrgUserCsv')}</DialogTitle>
      </DialogHeader>
      <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <DataForm
          baseId={`${baseId}-export-csv-org-user`}
          defaultData={[]}
          submitLabel={t.translate('words.kirim')}
          definitions={[
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.name`),
              key: 'name.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.aliasName`),
              key: 'aliasName.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.npwp`),
              key: 'npwp.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.address`),
              key: 'address.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.city`),
              key: 'city.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.email`),
              key: 'email.contains',
              type: 'text',
              show: () => {
                return AppMode.onPremise ? true : false
              }
            },
          ]}
          additionalAction={[
            {
              show: true,
              render: () => (
                <Button style={{ marginRight: 15 }} onClick={() => setExportCsvOrg(false)}>{t.translate('words.close')}</Button>
              )
            }
          ]}
          onSubmit={async (values, callback) => {
            try {
              var companyId = null
              var user = JSON.parse(localStorage.getItem('user'))
              console.log(user, "ini apa ?")
              // if(user.authorities[0].name == "ROLE_EPPT_ADMIN"){
              //   getPage = await service.get(params)
              // }
              if(window.location.href.indexOf('open') != -1){
                companyId = match.params.companyId
              }
              if(user.authorities[0].name == "ROLE_EPPT_OWNER"){
                companyId = user.company.id
              }

              try{
                var res = await service.exportSummaryParam(values, companyId)
                const blob = new Blob([res.data], {type: 'CSV'})
                var filename = res.headers.filename
                setExportSummaryOrg(false)

                iziToast.success({
                  message: `${t.translate('words.exportOrgUserCsv')} ${t.translate('words.prosesDone')}`
                })

                fileSaver.saveAs(blob, filename);
                
              }catch(e){
                errorService(e)
                setExportSummaryOrg(false)
              }
              
            } catch (e) {
              errorService(e)
              iziToast.error({
                message: `${t.translate('words.exportOrgUserCsv')} ${t.translate('words.prosesFail')}`
              })
              callback('', false, false, false)
            }
          }}
        >
        </DataForm>
      </DialogContent>
    </Dialog>
    <Dialog 
      disableFocusOnMount={true}
          initialFocus="sertel-dialog"
          id="sertel-dialog"
          onFocus={()=>{}}
          visible={dialog}
          onRequestClose={()=> {
            setDialog(false)
          }}
          style={{
            width: 1024,
            margin: '0px !important'
          }}
          width={1024}
          aria-labelledby="sertel-dialog"
    >
      <DialogContent>
        <DataForm 
          style={{
            margin: '0px !important',
            width: '100%',
            maxWidth: '100%'
          }}
          defaultData={{
            npwp: dialogItem.npwp
          }}
          // onChange={(formData, key, value)=> {
          //   if(key == 'file' && value){
          //     value.map((d)=> {
          //       var name = d.file.name
          //       select.map((c, index)=> {
          //         if(c.npwp == name.split('_')[0]){ 
          //           c.file = d
          //           c.filename = d.file.name
          //         } else{
          //           return value
          //         }
          //       })
          //     })
          //     setSelect(select)
          //   }
          // }}
          hintMessage={'Bila Anda belum menggunggah sertifikat elektronik. Silahkan unggah sertifikat elektronik Anda disini! Format File npwp_filename.p12'}
          showCommandbar={false}
          baseId = {baseId}
          title={t.translate('modules.organization.title')}
          definitions={[
            // {
            //   inputType : inputTypes.FILE_INPUT,
            //   label : t.translate('words.file'),
            //   key : 'file',
            //   type : 'file',
            //   // validation: 'required',
            //   multiple: true,
            //   multiline: true,
            //   accept: '.xls,.xlsx',
            // },
            {
              render : (
                <>
                  <div class="button-wrap">
                    <label class="button" for="upload"><i class="mdi mdi-download" />FILE</label>
                    <input id="upload" accept="application/x-pkcs12" type="file" multiple onChange={multipleFile} required/>
                  </div>
                  <TextField
                    id='file'
                    placeholder="No file chosen"
                    value={file.length > 0 ? file.length +" file telah terpilih" : ''}
                    // value={def.multiline
                    //   ? Array.isArray(value) ? value.map(d => (d.file.name)).toString() : ''
                    //   : value ? value.name : ''
                    // }
                    readOnly
                    style={{margin :'0px 0px 14px 5px', width:'80%', height: '60px'}}
                    // error={touched && !!error} errorText={error}
                    onInvalid={(e)=>{
                      setError({file : "The File is required"})
                      e.target.setCustomValidity(` `)
                      iziToast.warning({
                        title: 'Peringatan',
                        message: `Data formulir tidak valid. Silahkan cek kembali !`
                      })
                      console.log(e)
                    }}
                    required
                  />
                  <Button 
                    flat 
                    style={{margin :'10px 0px 0px 0px'}}
                    onClick={()=>{
                      try{
                        setFile([])
                        if(select && select.length > 0){
                          select.map((d)=>{
                            delete d.file
                            delete d.filename
                          })
                          setSelect([...select])
                        }
                      }catch(e){

                      }
                    }}
                  >
                    <FontIcon iconClassName="mdi mdi-delete"/>
                  </Button>
                  {error.file &&
                    <div className="message error-text mpk-font weight-B mpk-flex align-center" style={{ width: '100%', color: '#f1420c', fontSize: 12, padding: '8px 0', marginBottom: 12 }}>
                    <FontIcon
                      iconClassName="mdi mdi-alert"
                      style={{fontSize:15, color: '#f1420c' }}
                      className="mpk-margin-S margin-right"
                    />
                    {error.file}
                  </div>
                  }
                </>
              )
            },
            {
              render : (
                <>
                  {error.file && 
                    <div className="message error-text mpk-font weight-B mpk-flex align-center" style={{ width: '100%', color: '#f1420c', fontSize: 12, padding: '8px 0', marginBottom: 12 }}>
                      <FontIcon
                        iconClassName="mdi mdi-alert"
                        style={{fontSize:15, color: '#f1420c' }}
                        className="mpk-margin-S margin-right"
                      />
                      {error.file}
                    </div>
                  }
                </>
              )
            },
            {
              render: (
                <>
                  <Card style={{ width: '100%', padding: 6, marginTop: '24px', marginBottom: '14px'}}>
                  <CardContent>
                    <Table style={{ width: '100%' }}>
                      <TableHeader>
                        <TableRow>
                          <TableCell>{t.translate('words.non')}</TableCell>
                          <TableCell>{t.translate('words.orgNpwp')}</TableCell>
                          <TableCell>{t.translate('words.cert')}</TableCell>
                          <TableCell>{t.translate('words.password')}</TableCell>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                      {select && select.length > 0 &&
                        <>
                        {Object.values(select).map((d, index)=> {
                          return (
                            <TableRow>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{d.npwp}</TableCell>
                              <TableCell>{d.filename}</TableCell>
                              <TableCell>
                                <TextField 
                                  key="password"
                                  type="password"
                                  theme='underlines'
                                  placeholder='Masukan password disini'
                                  onChange={(value)=>{
                                    d.password = value.target.value
                                    error.password = null
                                    value.target.setCustomValidity(``)
                                  }}
                                  onInvalid={(e)=>{
                                    setError({password : "The Password is required"})
                                    e.target.setCustomValidity(` `)
                                    iziToast.warning({
                                      title: 'Peringatan',
                                      message: `Data formulir tidak valid. Silahkan cek kembali !`
                                    })
                                    console.log(e)
                                  }}
                                  required
                                />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                        </>
                      }
                      </TableBody>
                    </Table>
                    {error.password && 
                    <div className="message error-text mpk-font weight-B mpk-flex align-center" style={{ width: '100%', color: '#f1420c', fontSize: 12, padding: '8px 0', marginBottom: 12 }}>
                      <FontIcon
                        iconClassName="mdi mdi-alert"
                        style={{fontSize:15, color: '#f1420c' }}
                        className="mpk-margin-S margin-right"
                      />
                      {error.password}
                    </div>
                    }
                  </CardContent>
                </Card>
                </>
              )
            },
            {
              render: (
                <>
                <br />
                <div className="mpk-border border-all thin solid dark mpk-padding-N padding-all agrement mpk-full full-width mpk-hint" style={{ background: '#FEF6F4', color: 'black' }}>
                  <div class="container">
                    <h3 class="">Syarat dan Ketentuan Penyimpanan Data Sertifikat Elektronik</h3>
                    <div class="divider"></div>
                    <p class="">Terima kasih telah memilih layanan PT. MITRA PAJAKKU (selanjutnya disebut "Pajakku")</p>
                    <p>Syarat dan ketentuan ini mengatur secara spesifik mengenai syarat dan ketentuan penyimpanan data berupa sertifikat elektronik pada layanan aplikasi perpajakan melalui Pajakku yang merupakan bagian dari Syarat &amp; Ketentuan Umum Layanan Aplikasi Perpajakan Pajakku.</p>
                    <p>I. Umum</p>
                    <ul class="dec">
                      <li>1.1. Pajakku merupakan Penyedia Jasa Aplikasi Perpajakan (PJAP) resmi Direktorat Jenderal Pajak (DJP).</li>
                      <li>1.2. Syarat dan Ketentuan ini merupakan salah satu kewajiban Pajakku untuk memenuhi Peraturan Direktur Jenderal Pajak Nomor PER-10/PJ/2020 tentang Perubahan atas PER-11/PJ/2019 tentang Penyedia Jasa Aplikasi Perpajakan.</li>
                      <li>1.3. Pengguna Layanan Aplikasi Pajakku adalah karyawan, agen atau penerima kuasa dari badan hukum, organisasi, atau orang lain, sebagai perwakilan sah untuk dan atas nama badan hukum, organisasi, atau orang lain.</li>
                      <li>1.4. Syarat dan ketentuan ini berlaku terhadap seluruh pengguna yang mengakses dan/atau menggunakan Layanan Aplikasi Pajakku.</li>
                      <li>1.5. Sertifikat Elektronik dalam hal ini diartikan sebagai sertifikat yang bersifat elektronik yang memuat Tanda Tangan Elektronik dan identitas yang menunjukan status subjek hukum para pihak dalam transaksi elektronik yang dikeluarkan oleh Direktorat Jenderal Pajak.</li>
                      <li>1.6. Passphrase atau umum disebut kata sandi (password) merupakan serangkaian angka dan/atau huruf dan/atau karakter tertentu yang digunakan sebagai kunci untuk mengakses data yang tersimpan pada sertifikat elektronik.</li>
                    </ul>
                    <p>II. Akun Pengguna</p>
                    <ul class="dec">
                      <li>2.1. Untuk menggunakan Layanan Aplikasi Pajakku, Pengguna harus terlebih dahulu terdaftar pada Layanan Aplikasi Pajakku.</li>
                      <li>2.2. Pengguna diminta untuk melengkapi data-data yang diminta pada saat pendaftaran. Data-data yang diberikan harus benar, akurat dan lengkap.</li>
                      <li>2.3. Pengguna berkewajiban untuk menjaga kerahasiaan kata sandi. Kata sandi yang di berikan kepada karyawan, agen atau penerima kuasa dari badan hukum, organisasi, atau orang lain menjadi tanggung jawab Pengguna.</li>
                      <li>2.4. Pengguna wajib memberitahukan Pajakku jika diketahui adanya penggunaan Akun Pengguna dari pihak yang tidak berwewenang.</li>
                      <li>2.5. Atas permintaan Pengguna, Pajakku dapat membantu Pengguna untuk mengganti Sandi Akun melalui support@pajakku.com dengan melengkapi data-data yang diwajibkan.</li>
                    </ul>
                  </div>
                  <p>III. Data Pengguna</p>
                    <ul class="dec">
                      <li>3.1. Pengguna dengan ini menyatakan dan menjamin bahwa sertifikat elektronik, passphrase dan data lainnya yang didaftarkan pada Layanan Aplikasi Pajakku (selanjutnya disebut “Data Pengguna”) adalah akurat, benar, lengkap, dan sesuai dengan ketentuan peraturan perundang-undangan di Indonesia.</li>
                      <li>3.2. Pengguna bertanggung jawab atas Data Pengguna dan melepaskan Pajakku dari tuntutan apapun apabila Data Pengguna yang tersedia tidak benar atau tidak sesuai dengan ketentuan peraturan perundang-undangan di Indonesia.</li>
                      <li>3.3. Pengguna menyatakan dan menjamin bahwa:</li>
                      <li style={{ paddingRight: '56px' }}>3.3.1. Data Pengguna bebas dari data yang bukan milik Pengguna atau Pengguna tidak mempunyai hak untuk mengakses atau mengolah data tersebut;</li>
                      <li style={{ paddingRight: '56px' }}>3.3.2. Data Pengguna yang jika di akses, olah, atau gunakan adalah bebas dari pelanggaran hak pihak ketiga termasuk namun tidak terbatas terhadap hak kekayaan intelektual;</li>
                      <li style={{ paddingRight: '56px' }}>3.3.3. Data Pengguna bebas dari virus, trojan horses, spyware, malware, worms, time bombs, cancelbots, dan/atau segala hal yang dapat membahayakan atau merusak Layanan Aplikasi Pajakku baik secara sebagian atau keseluruhan;</li>
                      <li style={{ paddingRight: '56px' }}>3.3.4. Data Pengguna bebas dari tujuan baik langsung maupun tidak langsung untuk melakukan perbuatan yang melanggar hukum atau peraturan perundang-undangan yang berlaku di Indonesia.</li>
                      <li>3.4. Pajakku berhak untuk menggunakan, menyalin, mengirim, menyimpan dan melakukan back-up Data Pengguna dengan tujuan hanya untuk keperluan kewajiban perpajakan Pengguna.</li>
                      <li>3.5. Pajakku berkewajiban untuk menjaga Data Pengguna dan tidak akan memberikan Data Pengguna kepada pihak ketiga tanpa persetujuan Pengguna. Namun, Data Pengguna yang di berikan kepada pihak ketiga yang sudah diizinkan Pengguna, merupakan tanggung jawab Pengguna dan Pengguna melepaskan Pajakku dari tanggung jawab terhadap segala penyalahgunaan Data Pengguna yang dilakukan oleh pihak ketiga yang sudah diizinkan.</li>
                      <li>3.6. Pajakku berhak untuk tidak memberikan Data Pengguna kepada pihak ketiga (walaupun Pengguna telah memberikan izin) apabila Pajakku meyakini bahwa pemberian Data Pengguna tersebut dapat merugikan atau membahayakan Pajakku, melanggar Syarat dan Ketentuan ini atau melanggar hukum atau peraturan perundang-undangan yang berlaku di Indonesia.</li>
                    </ul>
                    <p>IV. Larangan</p>
                    <ul class="dec">
                      <li>4.1. Pengguna tidak diperkenankan untuk mengakses atau mencoba mendapatkan akses selain dari yang merupakan hak atau wewenang Pengguna.</li>
                      <li>4.2. Pengguna tidak diperkenankan untuk mengubah, menyalin, meniru, membongkar, atau melakukan reverse engineering atas layanan aplikasi Pajakku yang digunakan.</li>
                      <li>4.3. Pengguna tidak diperkenankan untuk mengirimkan atau memasukkan hal yang bersifat menghina ataupun melanggar hukum, seperti data atau materi lainnya yang terlindungi hak cipta atau rahasia dagang dimana Pengguna tidak memiliki hak untuk menggunakannya dalam kondisi apapun.</li>
                      <li>4.4. Pengguna tidak diperkenankan untuk menggunakan Layanan Aplikasi Pajakku dengan tujuan yang ilegal atau tidak sah. Pajakku berhak untuk membatasi atau meniadakan sama sekali akses Pengguna untuk menggunakan Layanan Aplikasi Pajakku lebih lanjut jika penggunaan tersebut menimbulkan pelanggaran terhadap hukum yang berlaku.</li>
                      <li>4.5. Pajakku berdasarkan kebijakan sendiri, berhak untuk menangguhkan atau menghentikan akses atau penggunaan terhadap Layanan Aplikasi Pajakku tanpa pemberitahuan terlebih dahulu kepada Pengguna apabila Pengguna melakukan pelanggaran terhadap Syarat dan Ketentuan ini atau peraturan perundang-undangan yang berlaku di Indonesia.</li>
                    </ul>
                    <p>V. Persetujuan</p>
                    <ul class="dec">
                      <li>5.1. Pengguna menyatakan dan menjamin bahwa Pengguna adalah individu yang sah secara hukum untuk terikat dalam perjanjian berdasarkan hukum Republik Indonesia, secara khusus terikat dalam Syarat dan Ketentuan ini, untuk menggunakan Layanan Aplikasi Pajakku.</li>
                      <li>5.2. Pengguna menyatakan dan menjamin untuk membebaskan Direktorat Jenderal Pajak dari segala tuntutan yang berkait dengan penyediaan Layanan Aplikasi Perpajakan Pajakku.</li>
                      <li>5.3. Pengguna memahami dan menyetujui bahwa menggunakan Layanan Aplikasi Pajakku dengan risiko Pengguna sendiri.</li>
                      <li>5.4. Pajakku dengan upaya terbaik menyediakan layanan untuk Pengguna, namun tidak memberikan jaminan bahwa penggunaan terhadap Layanan Aplikasi Pajakku akan selalu sesuai dengan harapan Pengguna, selalu ada dalam kualitas terbaik atau terbebas dari virus, kerusakan, bug, error, kehilangan data, dan gangguan lainnya dan Pengguna melepaskan Pajakku dari tuntutan sehubungan dengan terjadinya hal-hal tersebut.</li>
                    </ul>
                    <p>VI. Batasan Tanggung Jawab Pajakku</p>
                    <ul class="dec">
                      <li>6.1. Pajakku tidak bertanggung jawab terhadap kesalahan (human error) yang Pengguna lakukan dalam menggunakan Layanan Aplikasi Pajakku.</li>
                      <li>6.2. Pajakku tidak bertanggung jawab atas dampak dari segala kerugian yang timbul akibat tindakan pengguna yang secara langsung maupun tidak langsung dari penggunaan Layanan Aplikasi Pajakku.</li>
                      <li>6.3. Pajakku tidak bertanggung jawab atas kesalahan yang terjadi, yang termasuk namun tidak terbatas pada:</li>
                      <li style={{ paddingRight: '56px' }}>6.3.1. Kesalahan dan/atau ketidak akuratan data dalam sistem yang di masukkan Pengguna;</li>
                      <li style={{ paddingRight: '56px' }}>6.3.2. Perubahan kebijakan akibat peraturan baru dari seluruh otoritas yang berwenang; dan/atau</li>
                      <li style={{ paddingRight: '56px' }}>6.3.3. Gangguan server atau koneksi internet Pengguna saat menggunakan Layanan Aplikasi Pajakku.</li>
                    </ul>
                    <p>VII. Domisili Hukum dan Penyelesaian Perselisihan</p>
                    <ul class="dec">
                      <li>7.1. Syarat dan Ketentuan ini dilaksanakan menurut ketentuan hukum yang berlaku di Negara Kesatuan Republik Indonesia.</li>
                      <li>7.2. Setiap perselisihan dan permasalahan yang timbul berkaitan dengan pelaksanaan isi Syarat dan Ketentuan ini akan diselesaikan secara musyawarah dan mufakat oleh Pajakku dan Pengguna.</li>
                      <li>7.3. Apabila penyelesaian secara musyawarah dan mufakat tidak tercapai maka Pajakku dan Pengguna setuju untuk memilih domisili hukum yang tetap dan umum di Kantor Kepaniteraan Pengadilan Negeri Jakarta Barat yang demikian dengan tidak mengurangi hak Pajakku dan Pengguna untuk menuntut pihak lainnya melalui atau dihadapan pengadilan lainnya dalam wilayah Negara Republik Indonesia.</li>
                    </ul>
                    <p>Syarat dan Ketentuan ini dapat berubah dari waktu ke waktu, Pajakku tidak akan memberikan notifikasi kepada Pengguna atas setiap perubahan Syarat dan Ketentuan, namun Pajakku akan mengunggah setiap perubahan tersebut pada laman www.pajakku.com.</p>
                    <p>Dengan ini Pengguna menyatakan telah membaca, mengerti dan setuju untuk terikat pada Syarat dan Ketentuan ini serta perubahannya.</p>
                </div>
                </>
              )
            },
            {
              inputType : inputTypes.CHECKBOX,
              label : t.translate('words.aggreed'),
              key : 'aggree',
              type : 'text',
              validation: 'required'
            },
          ]}
          onSubmit={async (values, callback)=> {
            var proses = t.translate('words.uploadSertel')
            setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
            var errors = []
            console.log(select, file)
            try {
              bluebird.mapSeries(select, async (di, di2) => {
                setTimeout(async () => {
                  try {
                    var formData = new FormData()
                    formData.append('file', file[di2].file)
                    formData.append('pass', di.password)
                    formData.append('npwp', di.npwp)
                    let x = await service.uploadSertel(formData)
                  } catch (e) {
                    errors.push(await errorServiceGet(e, di, di2))
                  }
                  setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${select.length}` })
                  if ((di2 + 1) == select.length) {
                    setPloading({ loading: false, message: `` })
                    if (errors.length == 0) {
                      iziToast.success({
                        title: proses,
                        message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                      })
                    } else {
                      modalStore.showInfo({
                        title: '',
                        children: (
                          <List>
                            {errors.map((er) => {
                              return <ListItem primaryText={''} secondaryText={er}></ListItem>
                            })}
                          </List>
                        )
                      })
                    }
                    try {
                      var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                      if (checkboxRoot.checked) {
                        if (checkboxRoot.getAttribute('aria-checked')) {
                          checkboxRoot.click()
                          checkboxRoot.click()
                        } else {
                          checkboxRoot.click()
                        }
                      }
                      document.getElementsByClassName('mdi-reload')[0].click()
                    } catch (e) { }
                  }
                }, 1000 * di2)
              }) 
            } catch(e){}
            callback("", false, false, false)
          }}
        />
      </DialogContent>
    </Dialog>
    <Dialog 
          disableFocusOnMount={true}
          initialFocus="sertel-dialog"
          id="sertel-dialog"
          onFocus={()=>{}}
          visible={uploadSertel}
          onRequestClose={()=> {
            setUploadSertel(false)
          }}
          style={{
            width: 1024,
            margin: '0px !important'
          }}
          width={1024}
          aria-labelledby="sertel-dialog"
    >
      <DialogContent>
        <FormWrapper 
          style={{
            margin: '0px !important',
            width: '100%',
            maxWidth: '100%'
          }}
          defaultData={{
            npwp: dialogItem.npwp
          }}
          hintMessage={'Bila Anda belum menggunggah sertifikat elektronik. Silahkan unggah sertifikat elektronik Anda disini! Format File npwp_filename.p12'}
          showCommandbar={false}
          baseId = {baseId}
          title={t.translate('modules.organization.title')}
          definitions={[
            {
              inputType : inputTypes.FILE_INPUT,
              label : t.translate('words.file'),
              key : 'file',
              type : 'file',
              accept: 'application/x-pkcs12',
              validation: 'required',
            },
            {
              inputType : inputTypes.INPUT,
              label : t.translate('words.npwp'),
              key : 'npwp',
              type : 'text',
              validation: 'required',
              disabled: true
            },
            {
              inputType : inputTypes.INPUT,
              label : t.translate('words.password'),
              key : 'password',
              type : 'password',
              validation: 'required'
            },
            {
              render: (
                <>
                <br />
                <div className="mpk-border border-all thin solid dark mpk-padding-N padding-all agrement mpk-full full-width mpk-hint" style={{ background: '#FEF6F4', color: 'black' }}>
                  <div class="container">
                    <h3 class="">Syarat dan Ketentuan Penyimpanan Data Sertifikat Elektronik</h3>
                    <div class="divider"></div>
                    <p class="">Terima kasih telah memilih layanan PT. MITRA PAJAKKU (selanjutnya disebut "Pajakku")</p>
                    <p>Syarat dan ketentuan ini mengatur secara spesifik mengenai syarat dan ketentuan penyimpanan data berupa sertifikat elektronik pada layanan aplikasi perpajakan melalui Pajakku yang merupakan bagian dari Syarat &amp; Ketentuan Umum Layanan Aplikasi Perpajakan Pajakku.</p>
                    <p>I. Umum</p>
                    <ul class="dec">
                      <li>1.1. Pajakku merupakan Penyedia Jasa Aplikasi Perpajakan (PJAP) resmi Direktorat Jenderal Pajak (DJP).</li>
                      <li>1.2. Syarat dan Ketentuan ini merupakan salah satu kewajiban Pajakku untuk memenuhi Peraturan Direktur Jenderal Pajak Nomor PER-10/PJ/2020 tentang Perubahan atas PER-11/PJ/2019 tentang Penyedia Jasa Aplikasi Perpajakan.</li>
                      <li>1.3. Pengguna Layanan Aplikasi Pajakku adalah karyawan, agen atau penerima kuasa dari badan hukum, organisasi, atau orang lain, sebagai perwakilan sah untuk dan atas nama badan hukum, organisasi, atau orang lain.</li>
                      <li>1.4. Syarat dan ketentuan ini berlaku terhadap seluruh pengguna yang mengakses dan/atau menggunakan Layanan Aplikasi Pajakku.</li>
                      <li>1.5. Sertifikat Elektronik dalam hal ini diartikan sebagai sertifikat yang bersifat elektronik yang memuat Tanda Tangan Elektronik dan identitas yang menunjukan status subjek hukum para pihak dalam transaksi elektronik yang dikeluarkan oleh Direktorat Jenderal Pajak.</li>
                      <li>1.6. Passphrase atau umum disebut kata sandi (password) merupakan serangkaian angka dan/atau huruf dan/atau karakter tertentu yang digunakan sebagai kunci untuk mengakses data yang tersimpan pada sertifikat elektronik.</li>
                    </ul>
                    <p>II. Akun Pengguna</p>
                    <ul class="dec">
                      <li>2.1. Untuk menggunakan Layanan Aplikasi Pajakku, Pengguna harus terlebih dahulu terdaftar pada Layanan Aplikasi Pajakku.</li>
                      <li>2.2. Pengguna diminta untuk melengkapi data-data yang diminta pada saat pendaftaran. Data-data yang diberikan harus benar, akurat dan lengkap.</li>
                      <li>2.3. Pengguna berkewajiban untuk menjaga kerahasiaan kata sandi. Kata sandi yang di berikan kepada karyawan, agen atau penerima kuasa dari badan hukum, organisasi, atau orang lain menjadi tanggung jawab Pengguna.</li>
                      <li>2.4. Pengguna wajib memberitahukan Pajakku jika diketahui adanya penggunaan Akun Pengguna dari pihak yang tidak berwewenang.</li>
                      <li>2.5. Atas permintaan Pengguna, Pajakku dapat membantu Pengguna untuk mengganti Sandi Akun melalui support@pajakku.com dengan melengkapi data-data yang diwajibkan.</li>
                    </ul>
                  </div>
                  <p>III. Data Pengguna</p>
                    <ul class="dec">
                      <li>3.1. Pengguna dengan ini menyatakan dan menjamin bahwa sertifikat elektronik, passphrase dan data lainnya yang didaftarkan pada Layanan Aplikasi Pajakku (selanjutnya disebut “Data Pengguna”) adalah akurat, benar, lengkap, dan sesuai dengan ketentuan peraturan perundang-undangan di Indonesia.</li>
                      <li>3.2. Pengguna bertanggung jawab atas Data Pengguna dan melepaskan Pajakku dari tuntutan apapun apabila Data Pengguna yang tersedia tidak benar atau tidak sesuai dengan ketentuan peraturan perundang-undangan di Indonesia.</li>
                      <li>3.3. Pengguna menyatakan dan menjamin bahwa:</li>
                      <li style={{ paddingRight: '56px' }}>3.3.1. Data Pengguna bebas dari data yang bukan milik Pengguna atau Pengguna tidak mempunyai hak untuk mengakses atau mengolah data tersebut;</li>
                      <li style={{ paddingRight: '56px' }}>3.3.2. Data Pengguna yang jika di akses, olah, atau gunakan adalah bebas dari pelanggaran hak pihak ketiga termasuk namun tidak terbatas terhadap hak kekayaan intelektual;</li>
                      <li style={{ paddingRight: '56px' }}>3.3.3. Data Pengguna bebas dari virus, trojan horses, spyware, malware, worms, time bombs, cancelbots, dan/atau segala hal yang dapat membahayakan atau merusak Layanan Aplikasi Pajakku baik secara sebagian atau keseluruhan;</li>
                      <li style={{ paddingRight: '56px' }}>3.3.4. Data Pengguna bebas dari tujuan baik langsung maupun tidak langsung untuk melakukan perbuatan yang melanggar hukum atau peraturan perundang-undangan yang berlaku di Indonesia.</li>
                      <li>3.4. Pajakku berhak untuk menggunakan, menyalin, mengirim, menyimpan dan melakukan back-up Data Pengguna dengan tujuan hanya untuk keperluan kewajiban perpajakan Pengguna.</li>
                      <li>3.5. Pajakku berkewajiban untuk menjaga Data Pengguna dan tidak akan memberikan Data Pengguna kepada pihak ketiga tanpa persetujuan Pengguna. Namun, Data Pengguna yang di berikan kepada pihak ketiga yang sudah diizinkan Pengguna, merupakan tanggung jawab Pengguna dan Pengguna melepaskan Pajakku dari tanggung jawab terhadap segala penyalahgunaan Data Pengguna yang dilakukan oleh pihak ketiga yang sudah diizinkan.</li>
                      <li>3.6. Pajakku berhak untuk tidak memberikan Data Pengguna kepada pihak ketiga (walaupun Pengguna telah memberikan izin) apabila Pajakku meyakini bahwa pemberian Data Pengguna tersebut dapat merugikan atau membahayakan Pajakku, melanggar Syarat dan Ketentuan ini atau melanggar hukum atau peraturan perundang-undangan yang berlaku di Indonesia.</li>
                    </ul>
                    <p>IV. Larangan</p>
                    <ul class="dec">
                      <li>4.1. Pengguna tidak diperkenankan untuk mengakses atau mencoba mendapatkan akses selain dari yang merupakan hak atau wewenang Pengguna.</li>
                      <li>4.2. Pengguna tidak diperkenankan untuk mengubah, menyalin, meniru, membongkar, atau melakukan reverse engineering atas layanan aplikasi Pajakku yang digunakan.</li>
                      <li>4.3. Pengguna tidak diperkenankan untuk mengirimkan atau memasukkan hal yang bersifat menghina ataupun melanggar hukum, seperti data atau materi lainnya yang terlindungi hak cipta atau rahasia dagang dimana Pengguna tidak memiliki hak untuk menggunakannya dalam kondisi apapun.</li>
                      <li>4.4. Pengguna tidak diperkenankan untuk menggunakan Layanan Aplikasi Pajakku dengan tujuan yang ilegal atau tidak sah. Pajakku berhak untuk membatasi atau meniadakan sama sekali akses Pengguna untuk menggunakan Layanan Aplikasi Pajakku lebih lanjut jika penggunaan tersebut menimbulkan pelanggaran terhadap hukum yang berlaku.</li>
                      <li>4.5. Pajakku berdasarkan kebijakan sendiri, berhak untuk menangguhkan atau menghentikan akses atau penggunaan terhadap Layanan Aplikasi Pajakku tanpa pemberitahuan terlebih dahulu kepada Pengguna apabila Pengguna melakukan pelanggaran terhadap Syarat dan Ketentuan ini atau peraturan perundang-undangan yang berlaku di Indonesia.</li>
                    </ul>
                    <p>V. Persetujuan</p>
                    <ul class="dec">
                      <li>5.1. Pengguna menyatakan dan menjamin bahwa Pengguna adalah individu yang sah secara hukum untuk terikat dalam perjanjian berdasarkan hukum Republik Indonesia, secara khusus terikat dalam Syarat dan Ketentuan ini, untuk menggunakan Layanan Aplikasi Pajakku.</li>
                      <li>5.2. Pengguna menyatakan dan menjamin untuk membebaskan Direktorat Jenderal Pajak dari segala tuntutan yang berkait dengan penyediaan Layanan Aplikasi Perpajakan Pajakku.</li>
                      <li>5.3. Pengguna memahami dan menyetujui bahwa menggunakan Layanan Aplikasi Pajakku dengan risiko Pengguna sendiri.</li>
                      <li>5.4. Pajakku dengan upaya terbaik menyediakan layanan untuk Pengguna, namun tidak memberikan jaminan bahwa penggunaan terhadap Layanan Aplikasi Pajakku akan selalu sesuai dengan harapan Pengguna, selalu ada dalam kualitas terbaik atau terbebas dari virus, kerusakan, bug, error, kehilangan data, dan gangguan lainnya dan Pengguna melepaskan Pajakku dari tuntutan sehubungan dengan terjadinya hal-hal tersebut.</li>
                    </ul>
                    <p>VI. Batasan Tanggung Jawab Pajakku</p>
                    <ul class="dec">
                      <li>6.1. Pajakku tidak bertanggung jawab terhadap kesalahan (human error) yang Pengguna lakukan dalam menggunakan Layanan Aplikasi Pajakku.</li>
                      <li>6.2. Pajakku tidak bertanggung jawab atas dampak dari segala kerugian yang timbul akibat tindakan pengguna yang secara langsung maupun tidak langsung dari penggunaan Layanan Aplikasi Pajakku.</li>
                      <li>6.3. Pajakku tidak bertanggung jawab atas kesalahan yang terjadi, yang termasuk namun tidak terbatas pada:</li>
                      <li style={{ paddingRight: '56px' }}>6.3.1. Kesalahan dan/atau ketidak akuratan data dalam sistem yang di masukkan Pengguna;</li>
                      <li style={{ paddingRight: '56px' }}>6.3.2. Perubahan kebijakan akibat peraturan baru dari seluruh otoritas yang berwenang; dan/atau</li>
                      <li style={{ paddingRight: '56px' }}>6.3.3. Gangguan server atau koneksi internet Pengguna saat menggunakan Layanan Aplikasi Pajakku.</li>
                    </ul>
                    <p>VII. Domisili Hukum dan Penyelesaian Perselisihan</p>
                    <ul class="dec">
                      <li>7.1. Syarat dan Ketentuan ini dilaksanakan menurut ketentuan hukum yang berlaku di Negara Kesatuan Republik Indonesia.</li>
                      <li>7.2. Setiap perselisihan dan permasalahan yang timbul berkaitan dengan pelaksanaan isi Syarat dan Ketentuan ini akan diselesaikan secara musyawarah dan mufakat oleh Pajakku dan Pengguna.</li>
                      <li>7.3. Apabila penyelesaian secara musyawarah dan mufakat tidak tercapai maka Pajakku dan Pengguna setuju untuk memilih domisili hukum yang tetap dan umum di Kantor Kepaniteraan Pengadilan Negeri Jakarta Barat yang demikian dengan tidak mengurangi hak Pajakku dan Pengguna untuk menuntut pihak lainnya melalui atau dihadapan pengadilan lainnya dalam wilayah Negara Republik Indonesia.</li>
                    </ul>
                    <p>Syarat dan Ketentuan ini dapat berubah dari waktu ke waktu, Pajakku tidak akan memberikan notifikasi kepada Pengguna atas setiap perubahan Syarat dan Ketentuan, namun Pajakku akan mengunggah setiap perubahan tersebut pada laman www.pajakku.com.</p>
                    <p>Dengan ini Pengguna menyatakan telah membaca, mengerti dan setuju untuk terikat pada Syarat dan Ketentuan ini serta perubahannya.</p>
                </div>
                </>
              )
            },
            {
              inputType : inputTypes.CHECKBOX,
              label : t.translate('words.aggreed'),
              key : 'aggree',
              type : 'text',
              validation: 'required'
            },
          ]}
          onSubmit={async (values, callback)=> {
            var proses = t.translate('words.uploadSertel')
            setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
            try {
              var formData = new FormData()
              formData.append('file', values.file)
              formData.append('pass', values.password)
              formData.append('npwp', values.npwp)
              document.getElementsByClassName('mdi-reload')[0].click()
              var x = await service.uploadSertel(formData)
              if(x.data.status == 0){
                iziToast.error({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesFail')} ${x.data.message}`
                })
              } else {
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                })
              }
              setUploadSertel(false)
            } catch(e){
              var errors = []
              errors.push(await errorServiceGet(e, { id: dialogItem.npwp }, 0))
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            callback("", false, false, false)
          }}
        />
      </DialogContent>
    </Dialog>

    {/* <Dialog 
      disableFocusOnMount={true}
      initialFocus="sertel-dialog"
      id="sertel-dialog"
      onFocus={()=>{}}
      visible={dialog}
      onRequestClose={()=> {
        setDialog(false)
      }}
      style={{
        width: 1024,
        margin: '0px !important'
      }}
      width={1024}
      aria-labelledby="sertel-dialog"
    >
      <DialogContent>
        <FormWrapper 
          style={{
            margin: '0px !important',
            width: '100%',
            maxWidth: '100%'
          }}
          defaultData={{
            npwp: dialogItem.npwp
          }}
          showCommandbar={false}
          baseId = "mod-sertel-form"
          title={t.translate('modules.organization.title')}
          definitions={[
            {
              inputType: inputTypes.INPUT,
              label: t.translate('words.name'),
              key: 'name',
              type: 'text',
              validation : 'required'
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate('words.npwp'),
              key: 'npwp',
              type: 'text',
              mask: '##.###.###.#-###.###',
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('words.aliasName'),
              key: 'aliasName',
              type: 'text',
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('words.city'),
              key: 'city',
              type: 'text',
              validation : 'required',
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('words.postalCode'),
              key: 'postalCode',
              type: 'text',
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate('words.email'),
              key: 'email',
              type: 'email',
            },
          ]}
          onSubmit={async (values, callback)=> {
            var proses = t.translate('words.uploadSertel')
            setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
            try {
              var formData = new FormData()
              formData.append('npwp', values.npwp)
              document.getElementsByClassName('mdi-reload')[0].click()
              await service.uploadSertel(formData)
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } catch(e){
              var errors = []
              errors.push(await errorServiceGet(e, { id: dialogItem.npwp }, 0))
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            setDialog(false)
            setPloading({ loading: false, message: `` })
          }}
        />
      </DialogContent>
    </Dialog>  */}
    <TableWrapper useFilter={false}
      selectable = {true}
      baseId = {baseId}
      title = {t.translate(`modules.${basePath}.title`)}
      className = {className}
      defaultData={[]}
      defaultSortBy="createdDate"
      sideHeader={
        <DataForm 
          baseId={`${baseId}-search`}
          defaultData={search}
          submitIconClassName = 'mdi mdi-magnify'
          submitLabel={`${t.translate('words.cari')}`}
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button style={{ marginRight: 15 }} onClick={()=> {
                  setSearch({})
                  var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                  setTimeout(()=> {
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }, 1000)
                }}>{t.translate('words.hapus')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback)=> {
            values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
            setTimeout(()=> {
              document.getElementsByClassName('mdi-reload')[0].click()
              callback("", false, false, false)
            }, 1000)
          }}
          definitions={[
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.INPUT_MASK_NUMBER,
              label: t.translate(`words.identity`),
              key: 'npwp.contains',
              type: 'text',
              mask: '##.###.###.#-###.###',
              maskChar: '_',
              maskValue: 'string',
              width: '100%'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.name`),
              key: 'name.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.aliasName`),
              key: 'aliasName.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.address`),
              key: 'address.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.city`),
              key: 'city.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.postalCode`),
              key: 'postalCode.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.phone`),
              key: 'phone.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.email`),
              key: 'email.contains',
              type: 'text'
            },
          ]}
        />
      }
      columns={[
        {
          label: t.translate('words.name'),
          searchable: true,
          sortable : true,
          key:'name',
          render: item => (item.name)
        },
        {
          label: t.translate('words.aliasName'),
          searchable: true,
          sortable : true,
          key: 'aliasName',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.aliasName)
        },
        {
          label: t.translate('words.npwp'),
          searchable: true,
          sortable : true,
          key: 'npwp',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.npwp)
        },
        {
          label: t.translate('words.active'),
          searchable: true,
          sortable : true,
          key: 'active',
          type: TableWrapper.dataTypes.BOOLEAN,
          render: item => {
            if(item.active){
              return <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.ya")}</Chip>
            } else {
              return <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.tidak")}</Chip>
            }
          }
        },
        {
          label: t.translate('words.city'),
          searchable: true,
          sortable : true,
          key: 'city',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.city)
        },
        {
          label: t.translate('words.lastSync'),
          searchable: true,
          sortable : true,
          key: 'lastSynced',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastSynced)
        },
        {
          label: t.translate('words.createdBy'),
          searchable: true,
          sortable : true,
          key: 'createdBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdBy)
        },
        {
          label: t.translate('words.createdDate'),
          searchable: true,
          sortable : true,
          key: 'createdDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.createdDate)
        },
        {
          label: t.translate('words.lastModifiedBy'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedBy',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedBy)
        },
        {
          label: t.translate('words.lastModifiedDate'),
          searchable: true,
          sortable : true,
          key: 'lastModifiedDate',
          type: TableWrapper.dataTypes.STRING,
          render: item => (item.lastModifiedDate)
        },
      ]}
      actions={[
        new TableWrapper.action(`${t.translate('words.uploadSertel')}`, 'mdi mdi-tag', (item) => addSertel(item)),
        new TableWrapper.action(`${t.translate('words.Add')}`, 'mdi mdi-plus', () => history.push(`${basePath}/new`), true),
        new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => { deleteBulk(item)}, true),
        new TableWrapper.action(`${t.translate('words.exportCsv')}`, 'mdi mdi-file-excel', (item) => exportCsvBulk(item), true),
        new TableWrapper.action(`${t.translate('words.csvOrgUser')}`, 'mdi mdi-account-group', (item) => {exportCsvBulkOrg(item)}, true),
        new TableWrapper.action(`${t.translate('words.reportOrg')}`, 'mdi mdi-file', (item) => {exportSummary(item)}, true),
        new TableWrapper.action(`${t.translate('words.syncOrg')}`, 'mdi mdi-sync', (item) => {syncOrg(item)}, true),
      ]}
      itemActions={[
        new TableWrapper.action(`${t.translate('words.uploadSertel')}`, 'mdi mdi-tag', (item) => addOneSertel(item)),
        new TableWrapper.action(`${t.translate('words.moreInfo')}`, 'mdi mdi-bell', (item) => {
          localStorage.setItem('orgOp', JSON.stringify(item))
          history.push(`${basePath}/${item.id}`)}, true),
        new TableWrapper.action(`${t.translate('words.Delete')}`, 'mdi mdi-delete', (item) => deleteById(item), true),
      ]}
      setPage={search.page} onFetchData={ (params)=> {
        return (
          new Promise(async (resolve, reject)=> {
            params = {...params,...search}
            try {
              await initData()
              let user = JSON.parse(localStorage.getItem('user'))
              let owner = JSON.parse(localStorage.getItem('owner'))
              let getPage = {}
              if(user.authorities[0].name == "ROLE_EPPT_ADMIN"){
                getPage = await service.get(params)
              }
              if(window.location.href.indexOf('open') != -1){
                getPage = await service.findAll(params, match.params.companyId)
              }
              if(user.authorities[0].name == "ROLE_EPPT_OWNER"){
                var companyId = user.company.id
                getPage = await service.findAll(params, companyId)
              }
              delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
              setData(getPage.data)
              resolve(getPage)
            } catch(e){
              resolve({ data: [] })
              errorService(e)
            }
          })
        )
      }}
      showCommandbar={showCommandbar}
      showFilterPeriod={true}
    />
    </>
  )
}

export default inject('temporaryStore', 'modalStore')(observer(OpOrganization))
