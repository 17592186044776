import React, { useState, useEffect } from 'react'
import { CompanyCard, Masonry } from '../../libs/react-mpk/components'
import { inject, observer } from 'mobx-react'
import {Button} from 'react-md'
import t from 'counterpart'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import httpService from '../../libs/react-mpk/services/http.service'
import DataForm from '../../libs/react-mpk/components/DataForm'
import moment from 'moment'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import Bluebird from 'bluebird'

const Companies = ({ authStore, envStore }) => {
  const [search, setSearch] = useState({})

  useEffect(() => {
    try { 
      document.getElementsByClassName('mpk-margin-S margin-bottom mpk-full full-width')[0].style.display = "none"
    } catch(e) {
      console.log()
    }
  })
  return (
    <TableWrapper useFilter={false}
      title={t.translate('menu.companies')}
      baseId="mod-companies"
      // useFilter={authStore.user ? authStore.user.isSupport : false}
      render={(data) => (
        <div className="mpk-padding-N padding-all mpk-animation slide-in mpk-full full-width">
          <Masonry
            className="mpk-full full-width"
            baseId="mod-companies-masonry"
            items={data.map(d => (
              <CompanyCard 
                key={`company-${d.id}`}
                value={d}
                href={`/product/company/${d.id}`}
              />
            ))}
          />
        </div>
      )}
      sideHeader={
        <DataForm 
          baseId={`companies-search`}
          defaultData={search}
          submitIconClassName = 'mdi mdi-magnify'
          submitLabel={`${t.translate('words.cari')}`}
          additionalAction={[
            {
              show: true,
              render: ()=> (
                <Button style={{ marginRight: 15 }} onClick={()=> {
                  setSearch({})
                  localStorage.removeItem(`companies-saved-search`)
                  setTimeout(()=> {
                    document.getElementsByClassName('mdi-reload')[0].click()
                  }, 1000)
                }}>{t.translate('words.hapus')}</Button>
              )
            }
          ]}
          onSubmit={(values, callback)=> {
            if(values['date.equals']) values['date.equals'] = moment(new Date(values['date.equals'])).format('YYYY-MM-DD')
            setSearch(values)
            localStorage.setItem(`companies-saved-search`, JSON.stringify(values))
            setTimeout(()=> {
              document.getElementsByClassName('mdi-reload')[0].click()
              callback("", false, false, false)
            }, 1000)
          }}
          definitions={[
            {
              render: (
                <div className="mpk-data-filter">
                  <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                    <p>{t.translate(`words.pencarian`)}</p>
                  </div>
                </div>
              )
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.namaPerusahaan`),
              key: 'nama.contains',
              type: 'text'
            },
            {
              inputType: inputTypes.INPUT,
              label: t.translate(`words.email`),
              key: 'email.contains',
              type: 'text'
            },
          ]}
        />
      }
      setPage={search.page} onFetchData={async params=> {
        let { companies } = authStore.user
        let compani = []
        companies.map((d)=>{
          compani.push({
            name : d.name,
            id : d.id,
            address : d.address,
            email : d.email,
            city : d.city,
            industry : d.industry
          })
        })

        var getAll = await Bluebird.mapSeries(compani, (company, index)=>{
          return authStore.user.isSupport 
          ? httpService.stream(`${envStore.env.sso.host}/api/user_console/company_support`, {}, {
            useDefaultHost: false,
            useDefaultBaseUrl: false,
            params
          })
          : new Promise( async (resolve) => {
            resolve(company)
          })
        })
        if(search['nama.contains']){
          getAll = getAll.filter((d)=> {
            return d.name.toUpperCase().indexOf(search['nama.contains'].toUpperCase()) != -1  
          })
        }
        if(search['npwp.contains']){
          getAll = getAll.filter((d)=> {
            return d.npwp.indexOf(search['npwp.contains']) != -1  
          })
        }
        if(search['email.contains']){
          getAll = getAll.filter((d)=> {
            return d.email.toUpperCase().indexOf(search['email.contains'].toUpperCase()) != -1  
          })
        }
        if(search['address.contains']){
          getAll = getAll.filter((d)=> {
            return d.address.indexOf(search['address.contains']) != -1  
          })
        }
        if(search['city.contains']){
          getAll = getAll.filter((d)=> {
            return d.city.indexOf(search['city.contains']) != -1  
          })
        }
        return {
          data: getAll,
          headers: {
            'x-total-count': getAll.length
          }
        }
      }}
    />
  )
}

export default inject('authStore', 'envStore')(observer(Companies))
