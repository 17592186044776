import React, { useEffect, useState } from 'react'
import { inputTypes, formatTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from '../DaftarRincianSetorSendiri/DaftarRincianSetorSendiri.service'
import { autorun, toJs } from 'mobx'
import UtilService from '../../services/utilsService'
import _ from 'lodash'
import {
    TableContainer,
    Table,
    TableHeader,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    Tab,
  } from 'react-md'

  const DaftarRincianSetorSendiriPph4a2Form = ({ authStore, envStore, match, history, ...props }) => {
      const { inputTypes, definition } = FormWrapper
      const [data, setData] = useState({loading: true, content: {}})
      const [tableBody, setTableBody] = useState([])

      const baseName = `pph4a2`
      const basePath = `pph4a2`

      useEffect(() => {
        async function initData(){
            var disabled = true
            try {
                var pasals = props.data.filter((d)=> {
                    return d.type == "PPH4-2"
                })
                var formData = {}
                pasals.push({
                    id: "2b48c818-809f-44bd-8c49-41f5d5f38faf",
                    type: "PPH4-2",
                    no: "02",
                    description: "Jasa Konstruksi:",
                    code: " ",
                    dpp: 0,
                    pph: 0,
                    
                })
                pasals.map((d, indexD)=> {
                    if(d.no == "1") d.no = "01" 
                    if(d.no == "1a") d.no = "01a" 
                    if(d.no == "1b") d.no = "01b" 
                    if(d.no == "1c") d.no = "01c" 
                    if(d.no == "2") d.no = "02" 
                    if(d.no == "2a") d.no = "02a" 
                    if(d.no == "2b") d.no = "02b" 
                    if(d.no == "2c") d.no = "02c" 
                    if(d.no == "2d") d.no = "02d" 
                    if(d.no == "2e") d.no = "02e" 
                    if(d.no == "2f") d.no = "02f" 
                    if(d.no == "2g") d.no = "02g" 
                    if(d.no == "3") d.no = "03" 
                    if(d.no == "4") d.no = "04" 
                    if(d.no == "5") d.no = "05" 
                    formData[`dpp${d.no}`] = d.dpp
                    formData[`pph${d.no}`] = d.pph
                })
                var pasal = _.sortBy(pasals, "no")  
                setData({ loading: false, content: formData })
                // setData({ loading: false, content: {
                //     pph1: '213123',
                //     formData
                // } })
                try {
                    formData['dppTotal'] = props.total.pph42.bruto
                    formData['pphTotal'] = props.total.pph42.pph
                } catch(e){}
                tableBody.push([
                    {
                        render: (
                            <TableCell>{t.translate(`words.no`)}</TableCell>
                        )
                    },
                    {
                        render: (
                            <TableCell style={{ width: 500 }}>{t.translate(`words.uraian`)}</TableCell>
                        )
                    },
                    {
                        render: (
                            <TableCell>{t.translate(`words.kodeObjekPajak`)}</TableCell>
                        )
                    },
                    {
                        render: (
                            <TableCell>{t.translate(`words.jumlahDpp`)}</TableCell>
                        )
                    },
                    {
                        render: (
                            <TableCell>{t.translate(`words.jumlahPph`)}</TableCell>
                        )
                    },
                ])
                pasal.map((q, index)=> {
                    var no = index + 1
                    if(index == 1 || index == 2 || index == 4 || index == 5 || index == 6 || index == 7 || index == 8 || index == 9 || index == 10) no = ""
                    if(index == 3) no = "2"
                    if(index == 11) no = "3"
                    if(index == 12) no = "4"
                    if(index == 13) no = "5"
                    if(index == 14) no = "6"
                    if(index == 3){
                        tableBody.push([
                            {
                                render: (
                                    <TableCell style={{ whiteSpace: 'pre-wrap' }}>{no}</TableCell>
                                )
                            },
                            {
                                render: (
                                    <TableCell style={{ whiteSpace: 'pre-wrap' }}>{q.description}</TableCell>
                                )
                            },
                            {
                                render: (
                                    <TableCell></TableCell>
                                )
                            },
                            {
                                render: (
                                    <TableCell></TableCell>
                                )
                            },
                            {
                                render: (
                                    <TableCell></TableCell>
                                )
                            },
                        ])
                    } else {
                        tableBody.push([
                            {
                                render: (
                                    <TableCell style={{ whiteSpace: 'pre-wrap' }}>{no}</TableCell>
                                )
                            },
                            {
                                render: (
                                    <TableCell style={{ whiteSpace: 'pre-wrap' }}>{q.description}</TableCell>
                                )
                            },
                            {
                                render: (
                                    <TableCell>{q.code}</TableCell>
                                )
                            },
                            {
                                inputType: inputTypes.INPUT_MASK_NUMBER,
                                thousandSeparator: thousandSeparator,
                                decimalSeparator: decimalSeparator,
                                isNumericString: true,
                                label: ``,
                                key: `dpp${q.no}`,
                                type: 'text',
                                disabled,
                                className : 'inputRight'
                            },
                            {
                                inputType: inputTypes.INPUT_MASK_NUMBER,
                                thousandSeparator: thousandSeparator,
                                decimalSeparator: decimalSeparator,
                                isNumericString: true,
                                label: '',
                                key: `pph${q.no}`,
                                type: 'text',
                                disabled,
                                className : 'inputRight'
                            },
                        ])   
                    }
                })
                // total
                // tableBody.push([
                //     {
                //         render: (
                //             <TableCell style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}>6</TableCell>
                //         )
                //     },
                //     {
                //         render: (
                //             <TableCell style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}>{t.translate('words.total')}</TableCell>
                //         )
                //     },
                //     {
                //         render: (
                //             <TableCell></TableCell>
                //         )
                //     },
                //     {
                //         inputType: inputTypes.INPUT_MASK_NUMBER,
                //         thousandSeparator: thousandSeparator,
                //         decimalSeparator: decimalSeparator,
                //         isNumericString: true,
                //         label: '',
                //         key: `dppTotal`,
                //         type: 'text',
                //         disabled: disabled,
                //         className: 'totalBold'
                //     },
                //     {
                //         inputType: inputTypes.INPUT_MASK_NUMBER,
                //         thousandSeparator: thousandSeparator,
                //         decimalSeparator: decimalSeparator,
                //         isNumericString: true,
                //         label: '',
                //         key: `pphTotal`,
                //         type: 'text',
                //         disabled: disabled,
                //         className: 'totalBold'
                //     },
                // ])
                // total
                setTableBody(tableBody)
            } catch(e){
              console.log(e)
            }
        }
        initData()
      }, [props.data])

    var thousandSeparator = "."
    var decimalSeparator  = ","
    try {  
        if(localStorage.getItem('lang') == 'en'){
        thousandSeparator = ","
        decimalSeparator  = "."
        }
    } catch(e){}

      return (
          <>
            <FormWrapper 
                tableForm={true}
                style={{
                    maxWidth: '100%'
                }}
                className="fullWidth"
                loading={data.loading}
                actions={[]}
                onChange={(a,b,c)=> {}}
                baseId={`mod-form-${baseName}`}
                hintMessage={t.translate(`modules.${baseName}.formTitle`)}
                defaultData={data.content}
                definitions={{
                    header: [
                        // {
                        //     render: (
                        //         <TableCell>{t.translate(`words.no`)}</TableCell>
                        //     )
                        // },
                        // {
                        //     render: (
                        //         <TableCell style={{ width: 500 }}>{t.translate(`words.uraian`)}</TableCell>
                        //     )
                        // },
                        // {
                        //     render: (
                        //         <TableCell>{t.translate(`words.kodeObjekPajak`)}</TableCell>
                        //     )
                        // },
                        // {
                        //     render: (
                        //         <TableCell>{t.translate(`words.jumlahDpp`)}</TableCell>
                        //     )
                        // },
                        // {
                        //     render: (
                        //         <TableCell>{t.translate(`words.jumlahPph`)}</TableCell>
                        //     )
                        // }
                    ],
                    // body: [
                    //     // [
                    //     //     {
                    //     //         inputType: inputTypes.INPUT,
                    //     //         thousandSeparator: thousandSeparator,
                    //     //         decimalSeparator: decimalSeparator,
                    //     //         isNumericString: true,
                    //     //         label: '',
                    //     //         key: `pph1`,
                    //     //         type: 'text',
                                
                    //     //     },
                    //     // ]
                    // ]
                    body: tableBody
                }}
                showCommandbar={false}
            />
          </>
      )
  }

  export default inject('authStore', 'envStore')(observer(DaftarRincianSetorSendiriPph4a2Form))