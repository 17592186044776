import React, {useEffect, useState} from 'react'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { autorun, toJS } from 'mobx';
import UtilsService from './../../services/utilsService'
import ErrorService from './../../services/errorService'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import service from './User.service'
import { Dialog, DialogHeader, DialogTitle, DialogContent, DialogFooter  } from 'react-md'
import { useToggle } from '@react-md/utils';
import LoadingOverlay from 'react-loading-overlay'
import { Chip, ListItem, List, Button } from 'react-md'
import errorServiceGet from '../../services/errorServiceGet'
import iziToast from 'izitoast'
import Bluebird, { resolve } from 'bluebird'

const User = ({
  className       = '',
  showCommandbar  = true,
  history,
  modalStore,
  authStore
}) => {

  const basePath = `pengguna`
  const [organization, setOrganization] = useState([])
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  let [dialog, setDialog] = useState(false)
  let [dialogItem, setDialogItem] = useState({})
  let [search, setSearch] = useState({})

  const getOrganizations = async (values)=> {
    var params = {
      page: 0,
      size: 200
    }
    if(isNaN(values)){
      params['field'] = 'name'
      params['query'] = values
    } else {
      params['field'] = 'npwp'
      params['query'] = values
    }
    let organization = await service.getOrganization(params)
    var getAll = organization.data
    var organizationsFilter = []
    try {
      getAll.map((og)=> {
        og.label = og.npwp + " - " + og.name
        if(og.certExists) organizationsFilter.push({
          value: og,
          label: og.label
        })
      })
    } catch(e){
      organization.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        organizationsFilter.push({
          value: d,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
    return organizationsFilter
  }

  useEffect(() => {
    getOrganizations()
  }, [])

  const addSertel = async (item) => {
    setDialogItem(item)
    setDialog(true)
  }
  
  async function downloadTemplate(item) {
    setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })

    try {
      let res = await service.downloadTemplate(item.id)
      setPloading({ loading: false, message: `` })

      return res
    } catch (e) {
      setPloading({ loading: false, message: `` })
      ErrorService(e)
    }
  }

  return (
      <>
        <LoadingOverlay
          style={{
            position: 'inherit',
            background: 'red'
          }}
          active={ploading.loading}
          spinner
          text={ploading.message}
          >
        </LoadingOverlay>
        <Dialog 
          disableFocusOnMount={true}
          initialFocus="sertel-dialog"
          id="sertel-dialog"
          onFocus={()=>{}}
          visible={dialog}
          onRequestClose={()=> {
            setDialog(false)
          }}
          style={{
            width: 1024,
            margin: '0px !important'
          }}
          width={1024}
          aria-labelledby="sertel-dialog"
        >
          <DialogContent>
            <FormWrapper 
              style={{
                margin: '0px !important',
                width: '100%',
                maxWidth: '100%'
              }}
              defaultData={{
                npwp: dialogItem.fileName
              }}
              hintMessage={t.translate(`modules.${basePath}.formTitle`)}
              showCommandbar={false}
              baseId = "mod-sertel-form"
              title={t.translate(`modules.${basePath}.title`)}
              definitions={[
                {
                  inputType : inputTypes.FILE_INPUT,
                  label : t.translate('words.file'),
                  key : 'file',
                  type : 'file',
                  validation: 'required'
                },
              ]}
              onSubmit={async (values, callback)=> {
                var proses = t.translate('words.uploadSertel')
                setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
                try {
                  var formData = new FormData()
                  formData.append('file', values.file)
                  formData.append('password', values.password)
                  formData.append('npwp', values.npwp)
                  document.getElementsByClassName('mdi-reload')[0].click()
                  await service.uploadSertel(formData)
                  iziToast.success({
                    title: proses,
                    message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                  })
                } catch(e){
                  var errors = []
                  errors.push(await errorServiceGet(e, { id: dialogItem.npwp }, 0))
                  modalStore.showInfo({
                    title: '',
                    children: (
                      <List>
                        {errors.map((er)=> {
                          return <ListItem primaryText={''} secondaryText={er}></ListItem>
                        })}
                      </List>
                    )  
                  })
                }
                setDialog(false)
                setPloading({ loading: false, message: `` })
              }}
            />
          </DialogContent>
        </Dialog>
        <TableWrapper useFilter={false}
          selectable={true}
          baseId={`mod${basePath}`}
          title={t.translate(`modules.${basePath}.title`)}
          className={className}
          defaultData={[]}
          defaultSortBy="id"
          columns={[
            {
              label: t.translate('words.category'),
              searchable: true,
              sortable : true,
              key: 'category',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.category)
            },
            {
              label: t.translate('words.customImport'),
              searchable: true,
              sortable : true,
              key: 'customImport',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.customImport)
            },
            {
              label: t.translate('words.fileName'),
              searchable: true,
              sortable : true,
              key: 'upload',
              type: TableWrapper.dataTypes.STRING,
              render: item => {
                if(item.company != null) {
                  return JSON.stringify(item.upload.fileName)
                } else {
                  return JSON.stringify(item.upload)
                }
              }
            },
            {
              label: t.translate('words.startDate'),
              searchable: true,
              sortable : true,
              key: 'startDate',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.startDate)
            },
            {
              label: t.translate('words.endDate'),
              searchable: true,
              sortable : true,
              key: 'endDate',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.endDate)
            },
            {
              label: t.translate('words.status'),
              searchable: true,
              sortable : true,
              key: 'status',
              type: TableWrapper.dataTypes.STRING,
              render: item => {
                if(JSON.stringify(item.data.status) == "UPLOADING"){
                  return <Chip style={{ background: "#FFC108", color: 'white'}}>{(JSON.stringify(item.data.status)+"").toUpperCase()}</Chip>
                } else if(JSON.stringify(item.data.status) == "COMPLETED"){
                  return <Chip style={{ background: "#2096F3", color: 'white'}}>{(JSON.stringify(item.data.status)+"").toUpperCase()}</Chip>
                } else if(JSON.stringify(item.data.status) == "FINISH"){
                  return <Chip style={{ background: "#8BC34A", color: 'white'}}>{(JSON.stringify(item.data.status)+"").toUpperCase()}</Chip>
                }else if(JSON.stringify(item.data.status) == "FAILED"){
                  return <Chip style={{ background: "#F44336", color: 'white'}}>{(JSON.stringify(item.data.status)+"").toUpperCase()}</Chip>
                }else if(JSON.stringify(item.data.status) == "DELETED"){
                  return <Chip style={{ background: "grey", color: 'white'}}>{(JSON.stringify(item.data.status)+"").toUpperCase()}</Chip>
                }else {
                  return <Chip style={{ background: "black", color: 'white'}}>{(JSON.stringify(item.data.status)+"").toUpperCase()}</Chip>
                }
              }
            },
            {
              label: t.translate('words.message'),
              searchable: true,
              sortable : true,
              key: 'message',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.message)
            },
            {
              label: t.translate('words.stepNumber'),
              searchable: true,
              sortable : true,
              key: 'step',
              type: TableWrapper.dataTypes.NUMBER,
              render: item => (item.step)
            },
            {
              label: t.translate('words.count'),
              searchable: false,
              sortable : false,
              key: 'count',
              type: TableWrapper.dataTypes.NUMBER,
              render: item => (item.count)
            },
            {
              label: t.translate('words.total'),
              searchable: false,
              sortable : false,
              key: 'total',
              type: TableWrapper.dataTypes.NUMBER,
              render: item => (item.total)
            },
            {
              label: t.translate('words.createdBy'),
              searchable: true,
              sortable : true,
              key: 'createdBy',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.createdBy)
            },
            {
              label: t.translate('words.createdDate'),
              searchable: true,
              sortable : true,
              key: 'createdAt',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.createdAt)
            },
            {
              label: t.translate('words.lastModifiedBy'),
              searchable: true,
              sortable : true,
              key: 'updateBy',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.updateBy)
            },
            {
              label: t.translate('words.lastModifiedDate'),
              searchable: true,
              sortable : true,
              key: 'updateAt',
              type: TableWrapper.dataTypes.STRING,
              render: item => (item.updateAt)
            },
          ]}
          actions={[
            new TableWrapper.action(`${t.translate('words.template')}`, 'mdi mdi-file-pdf', (item) => downloadTemplate(item)),
            new TableWrapper.action(`${t.translate('words.historySpt')}`, 'mdi mdi-tag', (item) => addSertel(item))
          ]}
          itemActions={[
            new TableWrapper.action(`${t.translate('words.moreInfo')}`, 'mdi mdi-bell', (item) => {history.push(`${basePath}/${item.id}`)})
          ]}
          setPage={search.page} onFetchData={ async query => {
            let res = await getOrganizations();
            var getAll = await Bluebird.mapSeries(res, async (org, OrgIndex)=> {
              return new Promise(async (resolve)=> {
                try{
                  setTimeout(async ()=> {
                    try {
                      let getSearch = await service.getCertStatus(org.npwp)
                      if(getSearch && getSearch.data && getSearch.data.data){
                        org = {
                          ...org,
                          ...getSearch.data.data
                        }
                        resolve(org)
                      } else {
                        resolve(org)
                      }
                    } catch(e){}
                  }, 500 * OrgIndex)
                } catch(e){
                  resolve()
                  ErrorService(e)
                }
              })
            })
            return {
              data: getAll,
              headers: {
                'x-total-count': getAll.length
              }
            }
          }}
          showCommandbar={showCommandbar}
          showFilterPeriod={false}
        /> 
      </>
  )
}

export default inject('temporaryStore', 'modalStore', 'authStore')(observer(User))