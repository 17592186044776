import React, {useEffect, useState} from 'react'
import { inputTypes, formatTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import service from './Bp26.service'
import { autorun, toJS } from 'mobx';
import UtilsService from '../../services/utilsService'
import { TabsManager, Tabs, TabPanels, TabPanel, Text, Divider, Button } from 'react-md'
import ErrorService from './../../services/errorService'
import { clearFormat } from './../../libs/react-mpk/services/number.service'
import RSelect from 'react-select'
import _ from 'lodash'

const Bp263Form = ({ authStore, envStore, temporaryStore, match, history, ...props }) => {
  const { inputTypes, definition } = FormWrapper
  const [data, setData] = useState({loading: true, content: {}})
  const [taxobjectCode, setTaxObjectCode] = useState([])
  const [tarif, setTarif] = useState(true)
  const [netto, setNetto] = useState(true)
  const [other, setOther] = useState(false)
  const [skd, setSkb] = useState(false)
  const [dtp, setDtp] = useState(false)
  const [pph, setPph] = useState(true)
  const [tanpaFasilitas, setTanpaFasilitas] = useState(false)
  const [customRules, setCustomRules] = useState({})
  const [isHidden, setIsHidden] = useState(false)
  const [kopRate, setKopRate] = useState(0)

  const [showTaxobjectCode, setShowTaxobjectCode] = useState(true)

  const baseName = `bp`
  const basePath = `bp`

  useEffect(() => {
    async function initData(){
      var bpform = JSON.parse(localStorage.getItem('bpform'))
      setData({ loading: false, content: bpform })
      try {
        if(bpform.skd){
          setTarif(false)
          setNetto(false)
        } else {
          setTarif(true)
          setNetto(true)
        }
      } catch(e){}
      setTaxObjectCod()
    }
    // getTaxObjectCode('PPH26')
    initData()
  }, [])

  async function setTaxObjectCod(){
    try {
      var bpform = JSON.parse(localStorage.getItem('bpform'))
      if(bpform.objectCode){
        var zxc = taxobjectCode.filter((d)=> {
          return bpform.objectCode == d.value.code
        })
        if(zxc.length > 0){
          bpform.objectCode = zxc[0].value
        }
        localStorage.setItem('bpform', JSON.stringify(bpform))
      }
    } catch(e){}
  }

  useEffect(()=> {

  }, [taxobjectCode])

  async function getTaxObjectCode(type){
    setShowTaxobjectCode(false)
    var res;
    if(type == "PPH26"){
      res = await service.getTaxObjectCode(type)
    } else {
      res = {
        data: [
          {
            createdBy:	"system",
            createdDate:	"2018-07-11 13:44:35",
            id:	"13b13346-0447-41b0-8158-46b671ab396a",
            seq:	106,
            code:	"28-499-02",
            description:	"Penghasilan yang Diterima atau Diperoleh Sehubungan dengan Kerja Sama dengan Lembaga Pengelola Investasi (LPI)",
            kap:	"411128",
            kjs:	"499",
            rates:	7.5,
            netto: 100,
            type:	"PPH4-2",
            ss:	false,
          },
          {
            createdBy:	"system",
            createdDate:	"2018-07-11 13:44:35",
            id:	"13b13346-0447-41b0-8158-46b671ab396b",
            seq:	106,
            code:	"28-405-02",
            description:	"Hadiah undiah (yang diterima WP LN)",
            kap:	"411127",
            kjs:	"102",
            rates:	25,
            netto: 100,
            type:	"PPH4-2",
            ss:	false,
          }
        ]
      }
    }
    console.log(res.data)
    var taxobjectCode = []
    res.data.map((d)=> {
      d.label = `${d.code} - ${d.description}`
      taxobjectCode.push({ value: d, label: `${d.code} - ${d.description}`, rates: d.rates, code: d.code })
      taxobjectCode = _.sortBy(taxobjectCode, 'code')
      setTaxObjectCode(taxobjectCode)
    })
    setTimeout(()=> {
      setShowTaxobjectCode(true)
    }, 200)
    return taxobjectCode
  }

  var thousandSeparator = "."
  var decimalSeparator  = ","
  try {  
    if(localStorage.getItem('lang') == 'en'){
      thousandSeparator = ","
      decimalSeparator  = "."
    }
  } catch(e){}
  
  var onSubmit = async (data, callback) => {
    console.log(data)
    data.form3valid = true
    var bpform = JSON.parse(localStorage.getItem('bpform'))
    bpform = Object.assign(bpform, data)
    localStorage.setItem('bpform', JSON.stringify(bpform))
    props.activePanel()
  }
  try {
    var bpform = JSON.parse(localStorage.getItem('bpform'))
    var spt = JSON.parse(localStorage.getItem('spt'))
    if(bpform.status == 'CANCELED' || bpform.status == 'DELETED' || spt.status == 'SUBMIT' || spt.status == 'FINISH'){ 
      onSubmit = false
    }
  } catch(e){}
  return (
    <>
      <FormWrapper
        submitLabel={t.translate('words.next')}
        loading={data.loading}
        showCommandbar={false}
        baseId={`mod-form-${baseName}`}
        style={{
          maxWidth: '100%'
        }}
        defaultData={data.content}
        onChange={async (formData, key, value)=> {
          if(key == 'type'){
            formData['objectCode'] = null
            await getTaxObjectCode(value)
          }
          if(key == 'objectCode'){
              if(formData.identityAs == "1"){
                formData.rates = value.rates * 2
                setKopRate(value.rates * 2)
              } else {
                try {
                  var getRates = taxobjectCode.filter((d)=> {
                    return d.label == value
                  })
                  formData.rates = getRates[0].rates
                  setKopRate(getRates[0].rates)
                } catch(e){}
                // formData.rates = value.rates
                // setKopRate(value.rates) //UNI-1658
              }
              setTanpaFasilitas(true) //UNI-1658
          }
          if(key == 'other' && value){
            setOther(true)
            setTarif(false)
          } else if(key == 'other' && !value) {
            setOther(false)
            setTarif(true)
          }
          if(key == 'skd' && value){
            setSkb(true)
            formData['withoutIncome'] = false
            customRules['skdNo'] = 'required'
            setNetto(false)
            setTarif(false)
            setCustomRules(customRules)
          } else if(key == 'skd' && !value) {
            setSkb(false)
            setNetto(true)
            setTarif(true)
            delete customRules['skdNo']
            setCustomRules(customRules)
          }
          if(key == 'dtp' && value){
            setDtp(true)
            formData['withoutIncome'] = false
            customRules['dtpNo'] = 'required'
            //customRules['ntpnDtp'] = 'required'
            setCustomRules(customRules)
          } else if(key == 'dtp' && !value) {
            setDtp(false)
            delete customRules['dtpNo']
            delete customRules['ntpnDtp']
            setCustomRules(customRules)
          }
          if(key == 'rates'){
            formData['pph'] = Math.floor(formData['bruto'] * (formData['netto']/100) * (value/100)) 
          }
          if(key == 'bruto'){
            formData['pph'] = Math.floor(value * (formData['netto']/100) * (formData['rates']/100)) 
          }
          if(key == 'netto'){
            formData['pph'] = Math.floor(formData['bruto'] * (value/100) * (formData['rates']/100)) 
          }
          if(key == 'skd' && value){
            // formData['pph'] = 0 //UNI-1658
            setTarif(false)
          }
          if(key == 'other' && value){
            setPph(false)
            formData['withoutIncome'] = false
          } else if(key == 'other' && !value) {
            setPph(true)
          }
          if(key == 'withoutIncome' && value){
            formData['rates'] = kopRate 
            setTanpaFasilitas(true)
          } else if(key == 'withoutIncome' && !value) {
            setTanpaFasilitas(false)
          }
          if(key == "objectCode" && value){
            formData['withoutIncome'] = true //UNI-1658
            var realvalue = value.code
            if(realvalue == '27-100-05' || realvalue == '27-100-06' || realvalue == '27-100-07'){
              setNetto(false)
            } else {
              formData['netto'] = 100
              setNetto(true)
            }
          }
        }}
        // customRules={customRules}
        definitions={[
          // {
          //   inputType: inputTypes.INPUT,
          //   label: t.translate(`words.sptNpwp`),
          //   key: 'sptNpwp',
          //   width: '50%',
          //   type: 'text',
          //   disabled: true
          // },
          // {
          //   inputType: inputTypes.INPUT,
          //   label: t.translate(`words.sptRev`),
          //   key: 'sptRev',
          //   width: '50%',
          //   type: 'text',
          //   disabled: true
          // },
          // {
          //   inputType: inputTypes.INPUT,
          //   label: t.translate(`words.sptMonth`),
          //   key: 'sptMonth',
          //   width: '50%',
          //   type: 'text',
          //   disabled: true
          // },
          // {
          //   inputType: inputTypes.INPUT,
          //   label: t.translate(`words.sptYear`),
          //   key: 'sptYear',
          //   width: '50%',
          //   type: 'text',
          //   disabled: true
          // },
          // {
          //   render: (
          //     <Divider style={{ marginBottom: 25 }}/>
          //   )
          // },
          {
            inputType: inputTypes.SELECT,
            label: t.translate(`words.typeBP`),
            validation: 'required',
            key: 'type',
            type: 'text',
            options: [
              {label: "PPh Pasal 26", value: "PPH26"},
              {label: "PPh Pasal 4a2", value: "PPH4-2"}
            ]
          },
          // {
          //   inputType: inputTypes.REACT_SELECT,
          //   label: t.translate(`words.kodeObjekPajak`),
          //   key: 'objectCode',
          //   validation: 'required',
          //   labelKey: 'label', 
          //   valueKey: 'label',
          //   data: taxobjectCode,
          //   options : taxobjectCode
          // },
          {
            inputType: inputTypes.AUTOCOMPLETE,
            label: t.translate(`words.kodeObjekPajak`),
            key: 'objectCode',
            validation: 'required',
            labelKey: 'label', 
            id : "test",
            valueKey: 'label',
            type : 'search',
            data: taxobjectCode,
            show: showTaxobjectCode,
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            isNumericString: true,
            label: t.translate(`words.jumlahPenghasilanBruto`),
            key: 'bruto',
            type: 'text',
            width: '30%',
            validation: 'required',
            allowNegative: false,
            isAllowed: (values)=> {
              if(values.floatValue == 0){
                return false
              } else {
                return true 
              }
            }
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            isNumericString: true,
            label: t.translate(`words.netto`),
            key: 'netto',
            type: 'text',
            width: '25%',
            validation: 'required',
            disabled: netto
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            isNumericString: true,
            label: t.translate(`words.tarif`),
            key: 'rates',
            type: 'text',
            width: '15%',
            //validation: 'required',
            disabled: tarif
          },
          {
            inputType: inputTypes.INPUT_MASK_NUMBER,
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            isNumericString: true,
            label: t.translate(`words.pphYangDipotongAtauDTP`),
            key: 'pph',
            type: 'text',
            width: '30%',
            validation: 'required',
            disabled: pph
          },
          {
            inputType: inputTypes.CHECKBOX,
            label: t.translate(`words.withoutIncome`),
            key: 'withoutIncome',
            type: 'text',
            disabled: skd || dtp || other
          },
          {
            inputType: inputTypes.CHECKBOX,
            label: t.translate(`words.suratKeteranganDomisili`),
            key: 'skd',
            type: 'text',
            width: '100%',
            disabled: tanpaFasilitas || dtp || other
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.suratKeteranganDomisiliL`),
            key: 'skdNo',
            type: 'text',
            width: '50%',
            show: skd
          },
          // {
          //   inputType: inputTypes.INPUT,
          //   label: t.translate(`words.suratKeteranganDomisiliLL`),
          //   key: 'skdDate',
          //   type: 'date',
          //   width: '50%',
          //   show: skd,
          //   disabled: true
          // },
          {
            inputType: inputTypes.CHECKBOX,
            label: t.translate(`words.ditanggungPemerintahDTP`),
            key: 'dtp',
            type: 'text',
            width: '100%',
            disabled: tanpaFasilitas || skd || other
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.ditanggungPemerintahDTPL`),
            key: 'dtpNo',
            type: 'text',
            // width: '50%', //UNI-1542
            show: dtp
          },
          // UNI-1452
          // {
          //   inputType: inputTypes.INPUT,
          //   label: t.translate(`words.ditanggungPemerintahDTPNTPN`),
          //   key: 'ntpnDtp',
          //   type: 'text',
          //   width: '50%',
          //   show: dtp
          // },
          {
            inputType: inputTypes.CHECKBOX,
            label: t.translate(`words.fasilitasLainnya`),
            key: 'other',
            type: 'text',
            width: '50%',
            disabled: tanpaFasilitas || skd || dtp
          },
          {
            inputType: inputTypes.INPUT,
            label: t.translate(`words.fasilitasLainnyaL`),
            key: 'otherNo',
            type: 'text',
            show: other
          },
          // {
          //   inputType: inputTypes.SELECT,
          //   label: t.translate(`words.sayaMenyetujui`),
          //   key: 'signAggree',
          //   type: 'text',
          //   width: '100%',
          //   validation: 'required',
          //   options: [
          //     { label: t.translate(`words.withholdingTaxAgent`), value: 0 },
          //     { label: t.translate(`words.incomeRecipient`), value: 1 },
          //     { label: t.translate(`words.bookBalanceTransferByWitholdingTaxAgent`), value: 2 }
          //   ]
          // },
        ]}
        onSubmit={onSubmit}
      />
    </>
  )
}

export default inject('authStore', 'envStore', 'temporaryStore')(observer(Bp263Form))
