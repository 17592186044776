import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './DataEssp.service'
import moment from 'moment'
import { toast } from '../../libs/react-mpk/services'
import { format } from '../../libs/react-mpk/services/number.service'
import Hint from '../../libs/react-mpk/components/Hint/Hint'
import { Chip, ListItem, List, Button } from 'react-md'
import errorService from '../../services/errorService'
import { TableCheckbox } from 'react-md'
import { inject, observer } from 'mobx-react'
import iziToast from 'izitoast'
import LoadingOverlay from 'react-loading-overlay'
import bluebird from 'bluebird'
import errorServiceGet from '../../services/errorServiceGet'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import AppMode from '../../AppMode'
import fileSaver from 'file-saver'

const DataEssp = ({
  modalStore,
  className       = '',
  showCommandbar  = true,
  history,
  match
}) => {

  const basePath = `data-essp`
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [data, setData] = useState([])
  let [search, setSearch] = useState({})
  const baseId = 'mod-data-essp'

  useEffect(()=> {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
  }, [])

  var hitung = async function(){
    if(AppMode.onPremise){
      history.push(`/onpremise/essp/calc`)
    } else{
      history.push(`/product/company/${match.params.companyId}/essp/calc`)
    }
  }

  var generate = async function(){
    modalStore.showConfirm({
      title : t.translate('words.generate'),
      children : t.translate('words.askConfirm'),
      onSubmit : (callback) => {
        generateExec()
        callback()
      }
    }) 
  }

  var generateExec = async function(){
    try {
      var proses = t.translate('words.generate')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      var selectedDataOp = []
      selected.map((d)=> {
        selectedData.push(data[d].id)
        selectedDataOp.push(data[d])
      })
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      let res = null
      if(AppMode.onPremise){
        res = await service.generate(selectedDataOp)
      }else{
        res = await service.esspGenerate(selectedData)
      }
      setPloading({ loading: false, message: `` })
      var message = `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
      if(res && res.data && res.data.message) message = res.data.message
      iziToast.success({
        title: proses,
        message: message
      })
      try{
        var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
        if(checkboxRoot.checked){
          if(checkboxRoot.getAttribute('aria-checked')){
            checkboxRoot.click()
            checkboxRoot.click()
          } else {
            checkboxRoot.click()
          }
        }
        document.getElementsByClassName('mdi-reload')[0].click()
      }catch(e){}
    } catch(e){
      console.log(e)
      setPloading({ loading: false, message: `` })
      errorService(e)
    }
  }

  var generateAll = async function(){
    modalStore.showConfirm({
      title : t.translate('words.generate'),
      children : t.translate('words.askConfirm'),
      onSubmit : (callback) => {
        generateAllExec()
        callback()
      }
    }) 
  }

  var generateAllExec = async function(){
    try {
      var proses = t.translate('words.generate')
      var selectedData = []
      selectedData.push(data.id)
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      let res = await service.generateAll(selectedData)
      setPloading({ loading: false, message: `` })
      iziToast.success({
        title: proses,
        // message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
        message: res.data.message
      })
      try{
        var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
        if(checkboxRoot.checked){
          if(checkboxRoot.getAttribute('aria-checked')){
            checkboxRoot.click()
            checkboxRoot.click()
          } else {
            checkboxRoot.click()
          }
        }
        document.getElementsByClassName('mdi-reload')[0].click()
      }catch(e){}
    } catch(e){
      setPloading({ loading: false, message: `` })
      errorService(e)
    }
  }

  var save = async function(){
    modalStore.showConfirm({
      title : t.translate('words.save'),
      children : t.translate('words.askConfirm'),
      onSubmit : (callback) => {
        saveExec()
        callback()
      }
    }) 
  }

  var saveExec = async function(){
    try {
      var proses = t.translate('words.save')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      let res = await service.save(selectedData)
      setPloading({ loading: false, message: `` })
      iziToast.success({
        title: proses,
        message: res.data.message
        // message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
      })
      try{
        var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
        if(checkboxRoot.checked){
          if(checkboxRoot.getAttribute('aria-checked')){
            checkboxRoot.click()
            checkboxRoot.click()
          } else {
            checkboxRoot.click()
          }
        }
        document.getElementsByClassName('mdi-reload')[0].click()
      }catch(e){}
    } catch(e){
      setPloading({ loading: false, message: `` })
      errorService(e)
    }
  }

  var saveAll = async function(){
    modalStore.showConfirm({
      title : t.translate('words.save'),
      children : t.translate('words.askConfirm'),
      onSubmit : (callback) => {
        saveAllExec()
        callback()
      }
    }) 
  }

  var saveAllExec = async function(){
    try {
      var proses = t.translate('words.save')
      var selectedData = []
      selectedData.push(data.id)
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      let res = await service.saveAll(selectedData)
      setPloading({ loading: false, message: `` })
      iziToast.success({
        title: proses,
        message: res.data.message
        // message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
      })
      try{
        var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
        if(checkboxRoot.checked){
          if(checkboxRoot.getAttribute('aria-checked')){
            checkboxRoot.click()
            checkboxRoot.click()
          } else {
            checkboxRoot.click()
          }
        }
        document.getElementsByClassName('mdi-reload')[0].click()
      }catch(e){}
    } catch(e){
      setPloading({ loading: false, message: `` })
      errorService(e)
    }
  }

  async function csvBySelectedExc(item) {
    try {
      var proses = t.translate('words.cetak')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.csvBySelected(selectedData)
            iziToast.success({
              title: proses,
              message: proccedThis.data.message
            })
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              // iziToast.success({
              //   title: proses,
              //   message: `${t.translate('words.proses')} ${proses} ${t.translate('words.done')}`
              // })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function csvBySelected(item) {
    modalStore.showConfirm({
      title : t.translate('words.cetak'),
      children : t.translate('words.confirmPrint'),
      onSubmit : (callback) => {
        csvBySelectedExc(item)
        callback()
      }
    })
  }

  async function csvByFilterExc(item) {
    try {
      var proses = t.translate('words.cetak')
      var selectedData = []
      selectedData.push(data.id)
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.csvByFilter(selectedData)
            iziToast.success({
              title: proses,
              message: proccedThis.data.message
            })
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              // iziToast.success({
              //   title: proses,
              //   message: `${t.translate('words.proses')} ${proses} ${t.translate('words.done')}`
              // })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function csvByFilter(item) {
    modalStore.showConfirm({
      title : t.translate('words.cetak'),
      children : t.translate('words.confirmPrint'),
      onSubmit : (callback) => {
        csvByFilterExc(item)
        callback()
      }
    })
  }

  async function deleteBulkExc(item) {
    try {
      var proses = t.translate('words.hapus')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d)=> selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2)=> {
        setTimeout(async ()=> {
          try {
            let proccedThis = await service.bulkDelete([di.id])
          } catch(e){
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
          if((di2+1) == selectedData.length){
            setPloading({ loading: false, message: `` })
            if(errors.length == 0){
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er)=> {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )  
              })
            }
            try{
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if(checkboxRoot.checked){
                if(checkboxRoot.getAttribute('aria-checked')){
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            }catch(e){}
          }
        }, 1000 * di2)
      })
  } catch(e) {
      errorService(e)
    }
  }

  async function deleteBulk(item) {
    modalStore.showConfirm({
      title : t.translate('words.hapus'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteBulkExc(item)
        callback()
      }
    })
  }

  async function deleteByIdExc(item) {
    try {
      let res = await service.delete(item.id)
      document.getElementsByClassName('mdi-reload')[0].click()
    } catch(e) {
      errorService(e)
    }
  }

  async function deleteById(item) {
    modalStore.showConfirm({
      title : t.translate('words.delete'),
      children : t.translate('words.hapusIni'),
      onSubmit : (callback) => {
        deleteByIdExc(item)
        callback()
      }
    })
  }

  var actions = [
    new TableWrapper.action(`${t.translate('words.generate')}`, 'mdi mdi-play', (item) => { generate(item)}, true),
    new TableWrapper.action(`${t.translate('words.hitung')}`, 'mdi mdi-calculator', (item) => { hitung(item)}, true),
  ]

  if(AppMode.onPremise){
    actions = [
      new TableWrapper.action(`${t.translate('words.delete')}`, 'mdi mdi-delete', (item) => { deleteBulk(item)}, true),
      new TableWrapper.action(`${t.translate('words.generate')}`, 'mdi mdi-play', (item) => { generate(item)}, true),
      new TableWrapper.action(`${t.translate('words.generateAll')}`, 'mdi mdi-fast-forward', (item) => { generateAll(item)}, true),
      new TableWrapper.action(`${t.translate('words.save')}`, 'mdi mdi-content-save', (item) => { save(item)}, true),
      new TableWrapper.action(`${t.translate('words.saveAll')}`, 'mdi mdi-dns', (item) => { saveAll(item)}, true),
      new TableWrapper.action(`${t.translate('words.hitung')}`, 'mdi mdi-calculator', (item) => { hitung(item)}, true),
      new TableWrapper.action(`${t.translate('words.csvbySelected')}`, 'mdi mdi-printer', (item) => {csvBySelected(item)}, true),
      new TableWrapper.action(`${t.translate('words.csvbyFilter')}`, 'mdi mdi-printer', (item) => {csvByFilter(item)}, true),
    ]
  }
  var itemActions = []
  if(AppMode.onPremise){
    itemActions = [
      new TableWrapper.action(`${t.translate('words.delete')}`, 'mdi mdi-delete', (item) => { deleteById(item)}, true),
    ]
  }

  var columns = [
    {
      label: t.translate('words.npwp'),
      searchable: true,
      sortable : true,
      key: 'sptNpwp',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptNpwp)
    },
    {
      label: t.translate('words.kap'),
      searchable: true,
      sortable : true,
      key: 'kap',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.kap)
    },
    {
      label: t.translate('words.kjs'),
      searchable: true,
      sortable : true,
      key: 'kjs',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.kjs)
    },
    {
      label: t.translate('words.masa'),
      searchable: true,
      sortable : true,
      key: 'sptMonth',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptMonth)
    },
    {
      label: t.translate('words.tahun'),
      searchable: true,
      sortable : true,
      key: 'sptYear',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptYear)
    },
    {
      label: t.translate('words.pembetulan'),
      searchable: true,
      sortable : true,
      key: 'sptRev',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptRev)
    },
    {
      label: t.translate('words.jumlahSetor'),
      searchable: true,
      sortable : true,
      key: 'jumlahSetor',
      type: TableWrapper.dataTypes.STRING,
      render: item => (format(item.jumlahSetor))
    },
    {
      label: t.translate('words.setorSendiri'),
      searchable: true,
      sortable : true,
      key: 'setorSendiri',
      type: TableWrapper.dataTypes.BOOLEAN,
      render: item => {
        if(item.setorSendiri){
          return <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.ya")}</Chip>
        } else {
          return <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.tidak")}</Chip>
        }
      }
    },
    {
      label: t.translate('words.submit'),
      searchable: true,
      sortable : true,
      key: 'generated',
      type: TableWrapper.dataTypes.BOOLEAN,
      render: item => {
        if(item.generated){
          return <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.ya")}</Chip>
        } else {
          return <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.tidak")}</Chip>
        }
      }
    },
    /*{
      label: t.translate('words.complete'),
      searchable: true,
      sortable : true,
      key: 'completed',
      type: TableWrapper.dataTypes.BOOLEAN,
      render: item => {
        if(item.completed){
          return <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.ya")}</Chip>
        } else {
          return <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.tidak")}</Chip>
        }
      }
    },
    {
      label: t.translate('words.import'),
      searchable: true,
      sortable : true,
      key: 'import',
      type: TableWrapper.dataTypes.BOOLEAN,
      render: item => {
        if(item.import){
          return <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.ya")}</Chip>
        } else {
          return <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.tidak")}</Chip>
        }
      }
    },*/
    {
      label: t.translate('words.type'),
      searchable: true,
      sortable : true,
      key: 'type',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.type)
    },
    {
      label: t.translate('words.createdBy'),
      searchable: true,
      sortable : true,
      key: 'createdBy',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.createdBy)
    },
    {
      label: t.translate('words.createdDate'),
      searchable: true,
      sortable : true,
      key: 'createdDate',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.createdDate)
    },
    {
      label: t.translate('words.lastModifiedBy'),
      searchable: true,
      sortable : true,
      key: 'lastModifiedBy',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.lastModifiedBy)
    },
    {
      label: t.translate('words.lastModifiedDate'),
      searchable: true,
      sortable : true,
      key: 'lastModifiedDate',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.lastModifiedDate)
    },
  ]

  if(AppMode.onPremise){
    columns = [
      {
        label: t.translate('words.npwp'),
        searchable: true,
        sortable : true,
        key: 'sptNpwp',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.npwp)
      },
      {
        label: t.translate('words.kap'),
        searchable: true,
        sortable : true,
        key: 'kap',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.kodePajak)
      },
      {
        label: t.translate('words.kjs'),
        searchable: true,
        sortable : true,
        key: 'kjs',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.kodeSetoran)
      },
      {
        label: t.translate('words.masa'),
        searchable: true,
        sortable : true,
        key: 'masa',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.masa)
      },
      {
        label: t.translate('words.tahun'),
        searchable: true,
        sortable : true,
        key: 'sptYear',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.tahun)
      },
      {
        label: t.translate('words.pembetulan'),
        searchable: true,
        sortable : true,
        key: 'sptRev',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.pembetulan)
      },
      {
        label: t.translate('words.jumlahSetor'),
        searchable: true,
        sortable : true,
        key: 'jumlahSetor',
        type: TableWrapper.dataTypes.STRING,
        render: item => (format(item.jumlahSetor))
      },
      {
        label: t.translate('words.setorSendiri'),
        searchable: true,
        sortable : true,
        key: 'setorSendiri',
        type: TableWrapper.dataTypes.BOOLEAN,
        render: item => {
          if(item.setorSendiri){
            return <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.ya")}</Chip>
          } else {
            return <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.tidak")}</Chip>
          }
        }
      },
      {
        label: t.translate('words.submit'),
        searchable: true,
        sortable : true,
        key: 'submitted',
        type: TableWrapper.dataTypes.BOOLEAN,
        render: item => {
          if(item.submitted === 'Y'){
            return <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.ya")}</Chip>
          } else {
            return <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.tidak")}</Chip>
          }
        }
      },
      /*{
        label: t.translate('words.completed'),
        searchable: true,
        sortable : true,
        key: 'completed',
        type: TableWrapper.dataTypes.BOOLEAN,
        render: item => {
          if(item.completed === 'Y'){
            return <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.ya")}</Chip>
          } else {
            return <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.tidak")}</Chip>
          }
        }
      },*/
      // {
      //   label: t.translate('words.import'),
      //   searchable: true,
      //   sortable : true,
      //   key: 'import',
      //   type: TableWrapper.dataTypes.BOOLEAN,
      //   render: item => {
      //     if(item.import){
      //       return <Chip style={{ background: "#4CAF50", color: 'white' }}>{t.translate("words.ya")}</Chip>
      //     } else {
      //       return <Chip style={{ background: "#F44336", color: 'white' }}>{t.translate("words.tidak")}</Chip>
      //     }
      //   }
      // },
      {
        label: t.translate('words.type'),
        searchable: true,
        sortable : true,
        key: 'type',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.type)
      },
      {
        label: t.translate('words.createdBy'),
        searchable: true,
        sortable : true,
        key: 'createdBy',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.createdBy)
      },
      {
        label: t.translate('words.createdDate'),
        searchable: true,
        sortable : true,
        key: 'createdDate',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.createdDate)
      },
      {
        label: t.translate('words.lastModifiedBy'),
        searchable: true,
        sortable : true,
        key: 'lastModifiedBy',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.lastModifiedBy)
      },
      {
        label: t.translate('words.lastModifiedDate'),
        searchable: true,
        sortable : true,
        key: 'lastModifiedDate',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.lastModifiedDate)
      },
    ]
  }

  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
        >
      </LoadingOverlay>
      <TableWrapper useFilter={false}
        baseId={baseId}
        title={t.translate('modules.dataEssp.title')}
        className={className}
        selectable={true}
        defaultData={[]}
        defaultSortBy="createdDate"
        setPage={search.page} onFetchData={(params)=> {
          return (
            new Promise(async (resolve, reject)=> {
              params = {...params,...search}
              try {
                try {
                  var type = []
                  if(params['IMPORT']){
                    type.push('IMPORT')
                  }
                  if(params['HITUNG']){
                    type.push('HITUNG')
                  }
                  if(params['IMPORT'] || params['HITUNG']){
                    var stringIn = ""
                    type.map((d)=> {
                      stringIn += d + ","
                    })
                    params['type.in'] = stringIn
                  }
                } catch(e){
                  delete params['type.in']
                  delete params['IMPORT']
                  delete params['HITUNG']
                }
                try {
                  if(params['GENERATED_FALSE']){
                    params['generated.equals'] = false
                  }
                  if(params['GENERATED_TRUE']){
                    params['generated.equals'] = true
                  }
                } catch(e){
                  delete params['generated.equals']
                  delete params['GENERATED_FALSE']
                  delete params['GENERATED_TRUE']
                }
                let getPage = null
                if(AppMode.onPremise){
                  params['npwp.contains'] = params['sptNpwp.contains']
                  delete params['sptNpwp.contains']
                  params['kodePajak.contains'] = params['kap.equals']
                  delete params['kap.equals']
                  params['kodeSetoran.contains'] = params['kjs.equals']
                  delete params['kjs.equals']
                  params['masa.contains'] = params['sptMonth.equals']
                  delete params['sptMonth.equals']
                  params['tahun.contains'] = params['sptYear.equals']
                  delete params['sptYear.equals']
                  params['pembetulan.contains'] = params['sptRev.equals']
                  delete params['sptRev.equals']
                  var string = ""
                  if(params['GENERATED_FALSE']){
                    string += "Y,"
                  }
                  if(params['GENERATED_TRUE']){
                    string += "N,"
                  }
                  if(params['GENERATED_FALSE'] || params['GENERATED_TRUE']){
                    params['submitted.in'] = string
                  }
                  if(params['type.in'] && params['type.in'].lenght > 0){
                    var string2 = ""
                    params['type.in'].map((d)=> {
                      string2 += d + ","
                    })
                    params['type.in'] = string2
                  }
                } else {
                  delete params['npwp.contains']
                  delete params['kodePajak.contains']
                  delete params['kodeSetoran.contains']
                  delete params['masa.contains']
                  delete params['tahun.contains']
                  delete params['pembetulan.contains']
                }
                if(AppMode.onPremise){
                  getPage = await service.getOp(params)
                } else {
                  getPage = await service.get(params)
                }
                setData(getPage.data)
                delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                resolve(getPage)
              } catch(e){
                resolve()
                errorService(e)
              }
            })
          )
        }}
        sideHeader={
          <DataForm 
            baseId={`${baseId}-search`}
            defaultData={search}
            submitIconClassName = 'mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button style={{ marginRight: 15 }} onClick={()=> {
                    setSearch({})
                    var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                    setTimeout(()=> {
                      document.getElementsByClassName('mdi-reload')[0].click()
                    }, 1000)
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback)=> {
              if(values['createdDate.greaterOrEqualThan']) values['createdDate.greaterOrEqualThan'] = new Date(values['createdDate.greaterOrEqualThan'])
              if(values['createdDate.lessOrEqualThan']) values['createdDate.lessOrEqualThan'] = new Date(values['createdDate.lessOrEqualThan'])
              values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
              setTimeout(()=> {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false, false)
              }, 1000)
            }}
            definitions={[
              {
                render: (
                  <div className="mpk-data-filter">
                    <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
                      <p>{t.translate(`words.pencarian`)}</p>
                    </div>
                  </div>
                )
              },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: t.translate(`words.identity`),
                key: 'sptNpwp.contains',
                type: 'text',
                mask: '##.###.###.#-###.###',
                maskChar: '_',
                maskValue: 'string',
                width: '100%'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.kap`),
                key: 'kap.equals',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.kjs`),
                key: 'kjs.equals',
                type: 'text'
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.sptMonth`),
                key: 'sptMonth.equals',
                type: 'text',
                options: [
                    { label: '1', value: '1' },
                    { label: '2', value: '2' },
                    { label: '3', value: '3' },
                    { label: '4', value: '4' },
                    { label: '5', value: '5' },
                    { label: '6', value: '6' },
                    { label: '7', value: '7' },
                    { label: '8', value: '8' },
                    { label: '9', value: '9' },
                    { label: '10', value: '10' },
                    { label: '11', value: '11' },
                    { label: '12', value: '12' },
                ]
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.sptYear`),
                key: 'sptYear.equals',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.sptRev`),
                key: 'sptRev.equals',
                type: 'text'
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.import`),
                key: 'IMPORT',
                type: 'text',
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.tipeHitung`),
                key: 'HITUNG',
                type: 'text',
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.belumSubmit`),
                key: 'GENERATED_FALSE',
                type: 'text',
              },
              {
                inputType: inputTypes.CHECKBOX,
                label: t.translate(`words.sudahSubmit`),
                key: 'GENERATED_TRUE',
                type: 'text',
              },
            ]}
          />
        }
        columns={columns}
        actions={actions}
        itemActions={itemActions}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
    </>
  )
}

export default inject('modalStore')(observer(DataEssp))
