import React, { useEffect } from 'react'
import { Router, Switch, Route } from "react-router-dom"
import { PageWrapper } from '../../libs/react-mpk/wrapper'
import { sso } from '../../libs/react-mpk/hocs'
import { DashboardSample, TableSample, FormSample, LogEmailView, LogEmailHistoryView, SspDashboardForm, ImportEssp, ImportLogCsv, ImportLog, ImportBp, ImportDossDopp, ImportSetorSendiri, ImportSspPbk, DaftarBpeView, Dashboard, PbkHistory, Bp26View, Bp26Form, BpView, Doss2HistoryView, Bp26History, BpHistory, SspHistory, BpForm, Bp1Form, Bp2Form, Bp3Form, Bp4Form, DoppDokumenLainForm, IndukForm, ImportDataIn, SignerForm, SignerView, LawanTransaksiInForm, LawanTransaksiView, SspView, SspForm, PbkView, PbkForm, Doss1Form, Doss2View, Doss2Form, RincianObjekPajakPph22Form, RincianObjekPajakPph26Form, RincianObjekPajakPph23Form, RincianObjekPajakPph42Form, RincianObjekPajakPph15Form, RincianSetorSendiriPph4a2Form, RincianSetorSendiriPph15Form, RincianSetorSendiri, RincianObjekPajak, EksporDataInForm,  EksporData, ImportData } from '../../modules'
import { autorun, toJS } from 'mobx';
import Bp26BulkForm from '../../modules/Bp26/Bp26Bulk.form';
import BpBulkForm from '../../modules/Bp/BpBulk.form'
import { List, ListItem, FontIcon } from 'react-md'
import t from 'counterpart'

const Spt = ({match, history, authStore, navigationStore}) => {
  const historys = toJS(history)
  var companyId = match.params.companyId
  var sptId = match.params.sptId
  var spt = {
    month: '-',
    year: '-',
    rev: '-',
    npwp : "-"
  }
  try {
    var getspt = JSON.parse(localStorage.getItem('spt'))
    if(getspt){
      spt.year  = getspt.year
      spt.month = getspt.month
      spt.rev   = getspt.rev
      spt.npwp = getspt.npwp
      authStore.user.company.industry = getspt.npwp
    }
  } catch(e){
    console.log(e, 'getSpt')
  }

  // PERRBAIKAN DALAM LUAR SPT
  useEffect(()=> {
    setTimeout(()=> {
      if(window.bunifikasiopenspt){
        history.push(`/product/company/${companyId}/daftar-spt/open/${sptId}/dashboard`)
        window.bunifikasiopenspt = false
      } else{}
    }, 500)
  }, [])
  // PERRBAIKAN DALAM LUAR SPT

  useEffect(() => {
    try { 
      document.getElementsByClassName('mpk-font size-NS')[0].style.color = 'black'
      document.getElementsByClassName('mpk-font size-NS')[0].style.fontSize = '16px'
      document.getElementsByClassName('mpk-font size-NS')[0].style.fontWeight = 600
    } catch(e) {
      console.log(e)
    }
  }, [])

  var sidebarMenuActive = []
  var sidebarMenu = [
      {
        "label":`${t.translate('menu.mainMenu')}`
      },
      {
        "label": `${t.translate('menu.manajemen')}`,
        "children":[
          {
            "label":`${t.translate('menu.dashboard')}`,
            "index":"dashboard",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/dashboard`,
            "childPath":[]
          }
        ]
      },
      {
        "label": `${t.translate('menu.bp')}`,
        "children":[
          {
            "label":`${t.translate('menu.bpDn')}`,
            "index":"bp, dn, dalam, negeri, dalam negeri, dalam-negeri",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/bp`,
            "childPath":[]
          },
          {
            "label":`${t.translate('menu.bpLn')}`,
            "index":"bp, ln, luar, negeri, luar negeri, luar-negeri",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/bp26`,
            "childPath":[]
          },
          {
            "label":`${t.translate('menu.doss2')}`,
            "index":"pph, setor, sendiri, setor sendiri",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/doss2`,
            "childPath":[]
          },
        ]
      },
      {
        "label": `${t.translate('menu.lampiranSpt')}`,
        "children":[
          {
            "label":`${t.translate('menu.doss/dopp')}`,
            "index":"dopp dokumen lain, dopp, objek, daftar, potong, pungut, objek potong, objek pungut, objek-potong, objek-pungut, dokumen, lain, dokumen-lain, doss 1, pph, satu, daftar, objek, setor, sendiri, daftar-objek-setor-sendiri, setor sendiri, daftar objek, daftar objek setor sendiri, setor-sendiri, ss",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/doss1`,
            "childPath":[]
          },
          {
            "label":`${t.translate('menu.doss')}`,
            "index":"doss 2, daftar, doss2, doss, objek, pph, rincian, rincian pph, rincian-pph, ss, setor, sendiri,setor-sendiri",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/rincian-setor-sendiri`,
            "childPath":[]
          },
          {
            "label":`${t.translate('menu.dopp')}`,
            "index":"rincian daftar object pajak, rincian, daftar, objek, pajak, objek pajak, objek-pajak, rincian objek, dopp",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/rincian-objek-pajak`,
            "childPath":[]
          }
        ]
      },
      {
        "label": `${t.translate('menu.induk')}`,
        "children":[
          {
            "label":`${t.translate('menu.induk')}`,
            "index":"spt, induk, surat, pemberitahuan, tahunan, surat-pemberitahuan-tahunan, pajak",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/spt-induk`,
            "childPath":[]
          },
          {
            "label":`${t.translate('menu.bpe')}`,
            "index":"bpe",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/daftar-bpe`,
            "childPath":[]
          }
        ]
      },
      {
        "label": `${t.translate('menu.daftarSsp')}`,
        "children":[
          {
            "label":`${t.translate('menu.ssp')}`,
            "index":"ssp, daftar, surat, setoran, pajak",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/ssp`,
            "childPath":[]
          },
          {
            "label":`${t.translate('menu.pbk')}`,
            "index":"daftar, pbk, pemindahbukuan, buku, pindah",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/pbk`,
            "childPath":[]
          },
        ]
      },
      {
        "label": `${t.translate('menu.setting')}`,
        "children":[
          {
            "label":`${t.translate('menu.lt')}`,
            "index":"lawan-transaksi, pengaturan, transaksi, lawan, lt",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/lawan-transaksi`,
            "childPath":[]
          },
          {
            "label":`${t.translate('menu.signer')}`,
            "index":"penandatangan, signer, tanda, tangan, tandatangan, sign",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/signer`,
            "childPath":[]
          },
        ]
      }
  ]

  var sidebarMenuDjpId = [
      {
        "label":`${t.translate('menu.mainMenu')}`
      },
      {
        "label": `${t.translate('menu.manajemen')}`,
        "children":[
          {
            "label":`${t.translate('menu.dashboard')}`,
            "index":"dashboard",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/dashboard`,
            "childPath":[]
          }
        ]
      },
      {
        "label": `${t.translate('menu.bp')}`,
        "children":[
          {
            "label":`${t.translate('menu.bpDn')}`,
            "index":"bp, dn, dalam, negeri, dalam negeri, dalam-negeri",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/bp`,
            "childPath":[]
          },
          {
            "label":`${t.translate('menu.bpLn')}`,
            "index":"bp, ln, luar, negeri, luar negeri, luar-negeri",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/bp26`,
            "childPath":[]
          },
          {
            "label":`${t.translate('menu.doss2')}`,
            "index":"pph, setor, sendiri, setor sendiri",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/doss2`,
            "childPath":[]
          },
        ]
      },
      {
        "label": `${t.translate('menu.lampiranSpt')}`,
        "children":[
          {
            "label":`${t.translate('menu.doss')}`,
            "index":"doss 2, daftar, doss2, doss, objek, pph, rincian, rincian pph, rincian-pph, ss, setor, sendiri,setor-sendiri",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/rincian-setor-sendiri`,
            "childPath":[]
          },
          {
            "label":`${t.translate('menu.dopp')}`,
            "index":"rincian daftar object pajak, rincian, daftar, objek, pajak, objek pajak, objek-pajak, rincian objek, dopp",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/rincian-objek-pajak`,
            "childPath":[]
          }
        ]
      },
      {
        "label": `${t.translate('menu.induk')}`,
        "children":[
          {
            "label":`${t.translate('menu.induk')}`,
            "index":"spt, induk, surat, pemberitahuan, tahunan, surat-pemberitahuan-tahunan, pajak",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/spt-induk`,
            "childPath":[]
          },
          {
            "label":`${t.translate('menu.bpe')}`,
            "index":"bpe",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/daftar-bpe`,
            "childPath":[]
          }
        ]
      },
      {
        "label": `${t.translate('menu.daftarSsp')}`,
        "children":[
          {
            "label":`${t.translate('menu.ssp')}`,
            "index":"ssp, daftar, surat, setoran, pajak",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/ssp`,
            "childPath":[]
          },
          {
            "label":`${t.translate('menu.pbk')}`,
            "index":"daftar, pbk, pemindahbukuan, buku, pindah",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/pbk`,
            "childPath":[]
          },
        ]
      },
      {
        "label": `${t.translate('menu.setting')}`,
        "children":[
          {
            "label":`${t.translate('menu.lt')}`,
            "index":"lawan-transaksi, pengaturan, transaksi, lawan, lt",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/lawan-transaksi`,
            "childPath":[]
          },
          {
            "label":`${t.translate('menu.signer')}`,
            "index":"penandatangan, signer, tanda, tangan, tandatangan, sign",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/signer`,
            "childPath":[]
          },
        ]
      },
      {
        "label": `${t.translate('menu.logData')}`,
        "children": [
          {
            "label":`${t.translate('modules.logDataEmail.title')}`,
            "index":"email, log",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/log-email`,
            "childPath":[]
          },
        ]
      },
      {
        "label": `${t.translate('menu.import')}`,
        "children":[
          {
            "label":`${t.translate('menu.importBpExcel')}`,
            "index":"import, bukti potong",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/import`,
            "childPath":[]
          },
          {
            "label":`${t.translate('menu.importSetorSendiri')}`,
            "index":"import, setor, sendiri",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/import-setor-sendiri`,
            "childPath":[]
          },
          {
            "label":`${t.translate('menu.importDossDopp')}`,
            "index":"import, doss, dopp",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/import-doss-dopp`,
            "childPath":[]
          },
          {
            "label":`${t.translate('menu.importSspPbk')}`,
            "index":"import, ssp, pbk",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/import-ssp-pbk`,
            "childPath":[]
          },
          {
            "label":`${t.translate('menu.importEssp')}`,
            "index":"import, ssp, essp",
            "resourceUri":"",
            "iconClassName":"mdi mdi-layout",
            "path":`/product/company/${companyId}/daftar-spt/open/${sptId}/import-essp`,
            "childPath":[]
          },
        ]
      },
  ]

  try {
    var getspt = JSON.parse(localStorage.getItem('spt'))
    if(!getspt.djpId){
      sidebarMenuActive = sidebarMenuDjpId
    } else {
      sidebarMenuActive = sidebarMenu
    }
  } catch(e){
    sidebarMenuActive = sidebarMenuDjpId
  }

  return (
    <>
      <PageWrapper
        inverseTheme={true}
        style={{background: '#D14C21'}}
        sidebarCollapsible={true}
        sidebarDefaultCollapse={false}
        sidebarHeader={(
          <List style={{ width: '100%', background: 'white', padding: '0px', height: '80px' }}>
            <ListItem style={{ marginTop: '-3px' }} secondaryTextClassName="sidebar-spt" secondaryText={`${t.translate('words.pembetulan')} ${spt.rev}`} rightAddon={<FontIcon onClick={()=>{
              // window.location.href = `/product/company/${companyId}/daftar-spt`
              history.push(`/product/company/${companyId}/daftar-spt`)
              window.bunifikasiclosespt = true
            }} iconClassName="mdi mdi-close"></FontIcon>}>
              {`${spt.year} - ${spt.month}`}
            </ListItem>
          </List>
        )}
        sidebarMenu={sidebarMenuActive}
      >
        <Router history={history}>
          <Switch>
            <Route 
              path = '/product/company/:companyId/daftar-spt/open/:sptId/log-email/:id/history'
              render = {props => (
                <LogEmailHistoryView {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/daftar-spt/open/:sptId/log-email'
              render={props => (
                <LogEmailView {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/dashboard'
              render={props => (
                <Dashboard {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/ssp/:id/history'
              render={props => (
                <SspHistory {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/ssp/:id'
              render={props => (
                <SspForm {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/ssp'
              render={props => (
                <SspView {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/ssp-new-dashboard'
              render={props => (
                <SspDashboardForm {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/daftar-bpe'
              render={props => (
                <DaftarBpeView {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/pbk/:id/history'
              render={props => (
                <PbkHistory {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/pbk/:id'
              render={props => (
                <PbkForm {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/pbk'
              render={props => (
                <PbkView {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/rincian-setor-sendiri'
              render={props => (
                <RincianSetorSendiri {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/rincian-objek-pajak'
              render={props => (
                <RincianObjekPajak {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/daftar-spt/open/:sptId/doss2/:id/history'
              render={props => (
                <Doss2HistoryView {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/daftar-spt/open/:sptId/doss2/:id'
              render={props => (
                <Doss2Form {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/daftar-spt/open/:sptId/doss2'
              render={props => (
                <Doss2View {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/daftar-spt/open/:sptId/doss1'
              render={props => (
                <Doss1Form {...props}/>
              )}
            />
            <Route
              path='/product/company/:companyId/daftar-spt/open/:sptId/index'
              render={props => (
                <div />
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/lawan-transaksi/:id'
              render={props => (
                <LawanTransaksiInForm {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/lawan-transaksi'
              render={props => (
                <LawanTransaksiView {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/signer/:id'
              render={props => (
                <SignerForm {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/signer'
              render={props => (
                <SignerView {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/spt-induk'
              render={props => (
                <IndukForm {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/dopp-dokumen-lain'
              render={props => (
                <DoppDokumenLainForm {...props}/>
              )}
            />
            <Route
            path="/product/company/:companyId/daftar-spt/open/:sptId/bp/bp-bulk"
            render={(props) => <BpBulkForm {...props} />}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/bp-1/:id'
              render={props => (
                <Bp1Form {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/bp-2/:id'
              render={props => (
                <Bp2Form {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/bp-3/:id'
              render={props => (
                <Bp3Form {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/bp-4/:id'
              render={props => (
                <Bp4Form {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/bp/:id/history'
              render={props => (
                <BpHistory {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/bp/:id'
              render={props => (
                <BpForm {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/bp'
              render={props => (
                <BpView {...props}/>
              )}
            />
            <Route
            path="/product/company/:companyId/daftar-spt/open/:sptId/bp26/bp26-bulk"
            render={(props) => <Bp26BulkForm {...props} />}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/bp26/:id/history'
              render={props => (
                <Bp26History {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/bp26/:id'
              render={props => (
                <Bp26Form {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/bp26'
              render={props => (
                <Bp26View {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/import/:id/import-detail-csv'
              render={props => (
                <ImportLogCsv {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/import/:id/import-detail'
              render={props => (
                <ImportLog {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/import'
              render={props => (
                <ImportData {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/export'
              render={props => (
                <EksporData {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/import-bp'
              render={props => (
                <ImportBp {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/import-setor-sendiri'
              render={props => (
                <ImportSetorSendiri {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/import-doss-dopp'
              render={props => (
                <ImportDossDopp {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/import-ssp-pbk'
              render={props => (
                <ImportSspPbk {...props}/>
              )}
            />
            <Route 
              path='/product/company/:companyId/daftar-spt/open/:sptId/import-essp'
              render={props => (
                <ImportEssp {...props}/>
              )}
            />
          </Switch>
        </Router>
      </PageWrapper>
    </>
  )
}

export default sso({
  basePath: '/product/company/:companyId/daftar-spt/open/:sptId',
  url:{
    me:'/api/sso/company/:companyId/me'
  }
})(Spt)
