import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import t from 'counterpart'
import { ListItem, FontIcon, Dialog, DialogHeader, DialogTitle, DialogContent, Button } from 'react-md'
import SptService from '../../modules/DaftarSpt/DaftarSpt.service'
import AppMode from '../../AppMode'
import errorService from '../../services/errorService';
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { DataForm } from '../../libs/react-mpk/components'

const DefaulrSearch = (props, match, history) => {
  const [dialogSearch, setDialogSearch] = useState(false)
  const [organization, setOrganization] = useState([])
  const [search, setSearch] = useState({})

  useEffect(async ()=> {
    try { 
      if (localStorage.getItem(`default-search`)) {
        setSearch(JSON.parse((localStorage.getItem(`default-search`))))
      }else{
        setSearch({})
      }
      if(AppMode.onPremise){
        getOrganizationsOp()
      }else{
        getOrganizations()
      }
    } catch(e) {
      console.log(e)
    }
  }, [dialogSearch])

  const getOrganizations = async (values)=> {
    var params = {
      page: 0,
      size: 200
    }
    if(isNaN(values)){
      params['field'] = 'name'
      params['query'] = values
    } else {
      params['field'] = 'npwp'
      params['query'] = values
    }
    let organization = await SptService.getOrganization(params)
    var getAll = organization.data
    var organizationsFilter = []
    try {
      getAll.map((og)=> {
        og.label = og.npwp + " - " + og.name
        if(og.certExists) organizationsFilter.push({
          value: og,
          label: og.label
        })
      })
    } catch(e){
      organization.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        organizationsFilter.push({
          value: d,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
    return organizationsFilter
  }

  const getOrganizationsOp = async (values) => {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if (isNaN(values)) {
        params['name.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await SptService.getOrganizationOp(params)
      res.data.map((d) => {
        d.label = d.npwp + " - " + d.name
        d.value = d.npwp
      })
      setOrganization(res.data)
      return res.data
    } catch (e) {
      errorService(e)
      return []
    }
  }

  return (
    <>
      <Dialog
        visible={dialogSearch}
        disableFocusOnMount={() => { }}
        onRequestClose={() => {
          setDialogSearch(false)
        }}
        style={{
          width: 600,
        }}
      >
        <DialogHeader>
          <DialogTitle>{t.translate('words.defaultPencarian')}</DialogTitle>
        </DialogHeader>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
          <DataForm
            baseId={`company-default-search`}
            defaultData={search}
            hintShowIcon={true}
            definitions={[
              {
                inputType: inputTypes.REACT_SELECT,
                label: t.translate(`words.npwp`),
                key: 'npwp.in',
                labelKey: 'label',
                valueKey: 'label',
                data: organization,
                options: organization,
                async: true,
                defaultOptions: organization,
                isMulti: true,
                loadOptions: async (inputValues)=> {
                  if(AppMode.onPremise){
                    if(inputValues.length % 2){
                      var options = await getOrganizationsOp(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  } else {
                    if(inputValues.length % 2){
                      var options = await getOrganizations(inputValues)
                      return options
                    } else {
                      return organization
                    }
                  }
                },
              },{
                inputType: inputTypes.SELECT,
                label: t.translate(`words.month1`),
                key: 'month.greaterOrEqualThan',
                type: 'text',
                options: [
                  { label: '1', value: '1' },
                  { label: '2', value: '2' },
                  { label: '3', value: '3' },
                  { label: '4', value: '4' },
                  { label: '5', value: '5' },
                  { label: '6', value: '6' },
                  { label: '7', value: '7' },
                  { label: '8', value: '8' },
                  { label: '9', value: '9' },
                  { label: '10', value: '10' },
                  { label: '11', value: '11' },
                  { label: '12', value: '12' },
                ]
              },
              {
                inputType: inputTypes.SELECT,
                label: t.translate(`words.month2`),
                key: 'month.lessOrEqualThan',
                type: 'text',
                options: [
                  { label: '1', value: '1' },
                  { label: '2', value: '2' },
                  { label: '3', value: '3' },
                  { label: '4', value: '4' },
                  { label: '5', value: '5' },
                  { label: '6', value: '6' },
                  { label: '7', value: '7' },
                  { label: '8', value: '8' },
                  { label: '9', value: '9' },
                  { label: '10', value: '10' },
                  { label: '11', value: '11' },
                  { label: '12', value: '12' },
                ]
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.year`),
                key: 'year.equals',
                type: 'text'
              },
              {
                inputType: inputTypes.INPUT,
                label: t.translate(`words.rev`),
                key: 'rev.equals',
                type: 'text'
              },
            ]}
            additionalAction={[
              {
                show: true,
                render: () => (
                  <Button style={{ marginRight: 15 }} onClick={async (values, callback) => {
                    setSearch({})
                    localStorage.removeItem(`default-search`)
                    setDialogSearch(false)
                    if(document.getElementsByClassName('mdi-reload')[0]){
                      setTimeout(() => {
                        document.getElementsByClassName('mdi-reload')[0].click()
                      }, 1000)
                    }
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={async (values, callback) => {
              try {
                values.search = true
                if (values['createdDate.greaterOrEqualThan']) values['createdDate.greaterOrEqualThan'] = new Date(values['createdDate.greaterOrEqualThan'])
                if (values['createdDate.lessOrEqualThan']) values['createdDate.lessOrEqualThan'] = new Date(values['createdDate.lessOrEqualThan'])
                if(AppMode.onPremise){
                    if(values['npwp.in'] && values['npwp.in'].length > 0){
                    var stringIn = ""
                    values['npwp.in'].map((d)=> {
                      stringIn += d.value + ","
                    })
                    values['npwp.in'] = stringIn
                  }
                } else {
                  if(values['npwp.in'] && values['npwp.in'].length > 0){
                    var stringIn = ""
                    values['npwp.in'].map((d)=> {
                      stringIn += d.value.npwp + ","
                    })
                    values['npwp.in'] = stringIn
                  }
                }
                values.page = 0;
                localStorage.setItem(`default-search`, JSON.stringify(values))
                setDialogSearch(false)
                if(document.getElementsByClassName('mdi-reload')[0]){
                  setTimeout(() => {
                    document.getElementsByClassName('mdi-reload')[0].click()
                    callback("", false, false)
                  }, 1000)
                }
              } catch (e) {
                setDialogSearch(false)
                callback('', false, false, false)
              }
            }}
          >
          </DataForm>
        </DialogContent>
      </Dialog>
        <ListItem
          id={"default-pencarian-list"}
          className='default-search'
          secondaryTextClassName='default-search-secondary'
          rightAddon={<FontIcon id="delete-default-search" iconClassName={localStorage.getItem('default-search') ? 'mdi mdi-close-circle' :'mdi mdi-plus-circle'} onClick={()=>{ 
            if(localStorage.getItem('default-search')){
              localStorage.removeItem(`default-search`)
              setSearch({})
              if(document.getElementsByClassName('mdi-reload')[0]){
                setTimeout(() => {
                  document.getElementsByClassName('mdi-reload')[0].click()
                }, 1000)
              }
            }else{
              setDialogSearch(true) 
            }
          }} />}
          secondaryText={localStorage.getItem('default-search') ?
            <>
              {localStorage.getItem('default-search') ? `Bulan : ${search['month.greaterOrEqualThan'] ? search['month.greaterOrEqualThan'] : "-"} - ${search['month.lessOrEqualThan'] ? search['month.lessOrEqualThan'] : "-"} . ${search['year.equals'] ? search['year.equals'] : "Tahun"} `: ""}<br />
              {localStorage.getItem('default-search') ? `Pembetulan ${search['rev.equals'] ? search['rev.equals'] : "-"}`: null}
            </>
            : false
          }
        >{localStorage.getItem('default-search') ? `NPWP : ${search['npwp.in'] ? search['npwp.in'] : "-"}`: "Default Pencarian"}</ListItem>
    </>
  )
}

export default inject('envStore', 'navigationStore')(observer(DefaulrSearch));