import React, {useEffect, useState} from 'react'
import { TableWrapper } from '../../libs/react-mpk/wrapper'
import Modal from '../../libs/react-mpk/components/Modal'
import t from 'counterpart'
import service from './DaftarBpe.service'
import moment from 'moment'
import { inject, observer } from 'mobx-react'
import { toast } from '../../libs/react-mpk/services'
import { format } from '../../libs/react-mpk/services/number.service'
import errorService from './../../services/errorService'
import iziToast from 'izitoast'
import DataForm from '../../libs/react-mpk/components/DataForm'
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { Chip, ListItem, List, Button } from 'react-md'
import errorServiceGet from '../../services/errorServiceGet'
import bluebird from 'bluebird'
import LoadingOverlay from 'react-loading-overlay'
import UtilsService from './../../services/utilsService'
import { autorun, toJS } from 'mobx';
import AppMode from '../../AppMode'
import SptService from '../DaftarSpt/DaftarSpt.service'

const DaftarBpe = ({
  className       = '',
  showCommandbar  = true,
  history,
  modalStore,
  authStore
}) => {

  const basePath = `daftar-bpe`
  const baseId = 'mod-bpe'
  const [data, setData] = useState([])
  let [search, setSearch] = useState({})
  const [ploading, setPloading] = useState({ loading: false, message: '' })
  const [organization, setOrganization] = useState({})
  var insideSpt = false

  var dalamSpt = false
  try {
    if (window.location.href.indexOf('open') != -1) {
      dalamSpt = true
    } else {
      dalamSpt = false
    }
  } catch(e){}
  
  async function requestExc(item) {
    try {
        var proses = t.translate('words.request')
        var selected = JSON.parse(TableWrapper.getSelected(baseId))
        var selectedData = []
        selected.map((d)=> selectedData.push(data[d]))
        setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
        var errors = []
        bluebird.mapSeries(selectedData, async (di, di2)=> {
          setTimeout(async ()=> {
            try {
              let proccedThis = await service.getRequestReport(di.id)
            } catch(e){
              errors.push(await errorServiceGet(e, di, di2))
            }
            setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2+1}/${selectedData.length}` })
            if((di2+1) == selectedData.length){
              setPloading({ loading: false, message: `` })
              if(errors.length == 0){
                iziToast.success({
                  title: proses,
                  message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
                })
              } else {
                modalStore.showInfo({
                  title: '',
                  children: (
                    <List>
                      {errors.map((er)=> {
                        return <ListItem primaryText={''} secondaryText={er}></ListItem>
                      })}
                    </List>
                  )  
                })
              }
              try{
                var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
                if(checkboxRoot.checked){
                  if(checkboxRoot.getAttribute('aria-checked')){
                    checkboxRoot.click()
                    checkboxRoot.click()
                  } else {
                    checkboxRoot.click()
                  }
                }
                document.getElementsByClassName('mdi-reload')[0].click()
              }catch(e){}
            }
          }, 1000 * di2)
        })
    } catch(e) {
      console.log(e)
      errorService(e)
    }
  }

  async function request(item) {
    modalStore.showConfirm({
      title : t.translate('words.request'),
      children : t.translate('words.confirmRequest'),
      onSubmit : (callback) => {
        requestExc(item)
        callback()
      }
    })
  }

  const getSptNpwp = async ()=> {
    let sptNpwp = await service.get()
    var ltNpwp = [] 
    sptNpwp.data.map((d, e)=>{
      ltNpwp.push({
        label : d.sptNpwp,
        value : d.sptNpwp
      })
    })

    const uniqueAddresses = Array.from(new Set(ltNpwp.map(a => a.id)))
    .map(id => {
      return ltNpwp.find(a => a.id === id)
    })
    // console.log(uniqueAddresses)

    setOrganization(uniqueAddresses)
  }

  const getOrganizations = async (values)=> {
    var params = {
      page: 0,
      size: 200
    }
    if(isNaN(values)){
      params['field'] = 'name'
      params['query'] = values
    } else {
      params['field'] = 'npwp'
      params['query'] = values
    }
    let organization = await service.getOrganization(params)
    var getAll = organization.data
    var organizationsFilter = []
    try {
      getAll.map((og)=> {
        og.label = og.npwp + " - " + og.name
        if(og.certExists) organizationsFilter.push({
          value: og,
          label: og.label
        })
      })
    } catch(e){
      organization.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        organizationsFilter.push({
          value: d,
          label: d.label
        })
      })
    }
    setOrganization(organizationsFilter)
    return organizationsFilter
  }

  const getOrganizationsOp = async (values)=> {
    try {
      var params = {
        page: 0,
        size: 100,
        sort: 'createdDate,DESC'
      }
      if(isNaN(values)){
        params['name.contains'] = values
      } else {
        params['npwp.contains'] = values
      }
      let res = await SptService.getOrganizationOp(params)
      res.data.map((d)=> {
        d.label = d.npwp + " - " + d.name
        d.value = d.npwp
      })
      setOrganization(res.data)
      return res.data
    } catch(e){
      errorService(e)
      return []
    }
  }

  useEffect(async ()=> {
    if(localStorage.getItem(`${baseId}-saved-search`)){
      setSearch(JSON.parse((localStorage.getItem(`${baseId}-saved-search`))))
    }
    if(AppMode.onPremise){
      getOrganizationsOp()
    } else {
      getOrganizations()
    }
  }, [])

  var searchDef = [
    {
      render: (
        <div className="mpk-data-filter">
          <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
            <p>{t.translate(`words.pencarian`)}</p>
          </div>
        </div>
      )
    },
    {
      inputType: inputTypes.CHECKBOX,
      label: t.translate(`words.allNpwps`),
      key: 'allNpwps',
      type: 'text',
      show : AppMode.onPremise ? false : true
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.ntte`),
      key: 'ntte.contains',
      type: 'text'
    },
    {
      inputType: inputTypes.REACT_SELECT,
      label: t.translate(`words.identity`),
      key: 'npwpProfile.in',
      labelKey: 'label', 
      valueKey: 'label',
      data: organization,
      options: organization,
      async: true,
      defaultOptions: organization,
      isMulti: true,
      loadOptions: async (inputValues)=> {
        if(AppMode.onPremise){
          if(inputValues.length % 2){
            var options = await getOrganizationsOp(inputValues)
            return options
          } else {
            return organization
          }
        } else {
          if(inputValues.length % 2){
            var options = await getOrganizations(inputValues)
            return options
          } else {
            return organization
          }
        }
      },
      show: !insideSpt
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.name`),
      key: 'name.contains',
      type: 'text'
    },
    {
      inputType: inputTypes.SELECT,
      label: t.translate(`words.month`),
      key: 'month.equals',
      type: 'text',
      options: [
          { label: '1', value: '1' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: '4', value: '4' },
          { label: '5', value: '5' },
          { label: '6', value: '6' },
          { label: '7', value: '7' },
          { label: '8', value: '8' },
          { label: '9', value: '9' },
          { label: '10', value: '10' },
          { label: '11', value: '11' },
          { label: '12', value: '12' },
      ]
    },
    // {
    //   inputType: inputTypes.SELECT,
    //   label: t.translate(`words.month2`),
    //   key: 'sptMonth.lessOrEqualThan',
    //   type: 'text',
    //   options: [
    //       { label: '1', value: '1' },
    //       { label: '2', value: '2' },
    //       { label: '3', value: '3' },
    //       { label: '4', value: '4' },
    //       { label: '5', value: '5' },
    //       { label: '6', value: '6' },
    //       { label: '7', value: '7' },
    //       { label: '8', value: '8' },
    //       { label: '9', value: '9' },
    //       { label: '10', value: '10' },
    //       { label: '11', value: '11' },
    //       { label: '12', value: '12' },
    //   ]
    // },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.year`),
      key: 'year.equals',
      type: 'text'
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.rev`),
      key: 'rev.equals',
      type: 'text'
    },
    {
      inputType: inputTypes.DATEPICKER,
      label: t.translate(`words.tanggal1`),
      key: 'createdDate.greaterOrEqualThan',
      type: 'date'
    },
    {
      inputType: inputTypes.DATEPICKER,
      label: t.translate(`words.tanggal2`),
      key: 'createdDate.lessOrEqualThan',
      type: 'date'
    },
    {
      inputType: inputTypes.INPUT,
      label: t.translate(`words.createdBy`),
      key: 'createdBy.contains',
      type: 'text'
    }
  ]

  if(window.location.href.indexOf('open') != -1){
    // delete searchDef[1]
    searchDef = [
      {
        render: (
          <div className="mpk-data-filter">
            <div className={`mpk-label mpk-font size-NS mpk-margin-N margin-bottom`}>
              <p>{t.translate(`words.pencarian`)}</p>
            </div>
          </div>
        )
      },
      // {
      //   inputType: inputTypes.CHECKBOX,
      //   label: t.translate(`words.allNpwps`),
      //   key: 'allNpwps',
      //   type: 'text',
      //   show : AppMode.onPremise ? false : true
      // },
      {
        inputType: inputTypes.INPUT,
        label: t.translate(`words.ntte`),
        key: 'ntte.contains',
        type: 'text'
      },
      // {
      //   inputType: inputTypes.REACT_SELECT,
      //   label: t.translate(`words.identity`),
      //   key: 'sptNpwp.in',
      //   labelKey: 'label', 
      //   valueKey: 'label',
      //   data: organization,
      //   options: organization,
      //   async: true,
      //   defaultOptions: organization,
      //   isMulti: true,
      //   loadOptions: async (inputValues)=> {
      //     if(AppMode.onPremise){
      //       if(inputValues.length % 2){
      //         var options = await getOrganizationsOp(inputValues)
      //         return options
      //       } else {
      //         return organization
      //       }
      //     } else {
      //       if(inputValues.length % 2){
      //         var options = await getOrganizations(inputValues)
      //         return options
      //       } else {
      //         return organization
      //       }
      //     }
      //   },
      //   show: !insideSpt
      // },
      // {
      //   inputType: inputTypes.INPUT,
      //   label: t.translate(`words.name`),
      //   key: 'name.contains',
      //   type: 'text'
      // },
      // {
      //   inputType: inputTypes.SELECT,
      //   label: t.translate(`words.month`),
      //   key: 'month.equals',
      //   type: 'text',
      //   options: [
      //       { label: '1', value: '1' },
      //       { label: '2', value: '2' },
      //       { label: '3', value: '3' },
      //       { label: '4', value: '4' },
      //       { label: '5', value: '5' },
      //       { label: '6', value: '6' },
      //       { label: '7', value: '7' },
      //       { label: '8', value: '8' },
      //       { label: '9', value: '9' },
      //       { label: '10', value: '10' },
      //       { label: '11', value: '11' },
      //       { label: '12', value: '12' },
      //   ]
      // },
      // {
      //   inputType: inputTypes.SELECT,
      //   label: t.translate(`words.month2`),
      //   key: 'sptMonth.lessOrEqualThan',
      //   type: 'text',
      //   options: [
      //       { label: '1', value: '1' },
      //       { label: '2', value: '2' },
      //       { label: '3', value: '3' },
      //       { label: '4', value: '4' },
      //       { label: '5', value: '5' },
      //       { label: '6', value: '6' },
      //       { label: '7', value: '7' },
      //       { label: '8', value: '8' },
      //       { label: '9', value: '9' },
      //       { label: '10', value: '10' },
      //       { label: '11', value: '11' },
      //       { label: '12', value: '12' },
      //   ]
      // },
      // {
      //   inputType: inputTypes.INPUT,
      //   label: t.translate(`words.year`),
      //   key: 'year.equals',
      //   type: 'text'
      // },
      // {
      //   inputType: inputTypes.INPUT,
      //   label: t.translate(`words.rev`),
      //   key: 'rev.equals',
      //   type: 'text'
      // },
      {
        inputType: inputTypes.DATEPICKER,
        label: t.translate(`words.tanggal1`),
        key: 'createdDate.greaterOrEqualThan',
        type: 'date'
      },
      {
        inputType: inputTypes.DATEPICKER,
        label: t.translate(`words.tanggal2`),
        key: 'createdDate.lessOrEqualThan',
        type: 'date'
      },
      {
        inputType: inputTypes.INPUT,
        label: t.translate(`words.createdBy`),
        key: 'createdBy.contains',
        type: 'text'
      }
    ]
  }

  async function reportBulkExc(item) {
    try {
      var proses = t.translate('words.report')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d].id))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries([1], async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.postReport(selectedData)
            window.open(proccedThis.data.data.url)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == 1) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function reportExc(item) {
    try {
      var proses = t.translate('words.report')
      var selected = JSON.parse(TableWrapper.getSelected(baseId))
      var selectedData = []
      selected.map((d) => selectedData.push(data[d]))
      setPloading({ loading: true, message: `${t.translate('words.prosesData')}` })
      var errors = []
      bluebird.mapSeries(selectedData, async (di, di2) => {
        setTimeout(async () => {
          try {
            let proccedThis = await service.getReport(di.id)
            window.open(proccedThis.data.data.url)
          } catch (e) {
            errors.push(await errorServiceGet(e, di, di2))
          }
          setPloading({ loading: true, message: `${t.translate('words.prosesData')} ${di2 + 1}/${selectedData.length}` })
          if ((di2 + 1) == selectedData.length) {
            setPloading({ loading: false, message: `` })
            if (errors.length == 0) {
              iziToast.success({
                title: proses,
                message: `${t.translate('words.proses')} ${proses} ${t.translate('words.prosesDone')}`
              })
            } else {
              modalStore.showInfo({
                title: '',
                children: (
                  <List>
                    {errors.map((er) => {
                      return <ListItem primaryText={''} secondaryText={er}></ListItem>
                    })}
                  </List>
                )
              })
            }
            try {
              var checkboxRoot = document.getElementById(`${baseId}-selectable-rows-root-checkbox`)
              if (checkboxRoot.checked) {
                if (checkboxRoot.getAttribute('aria-checked')) {
                  checkboxRoot.click()
                  checkboxRoot.click()
                } else {
                  checkboxRoot.click()
                }
              }
              document.getElementsByClassName('mdi-reload')[0].click()
            } catch (e) { }
          }
        }, 1000 * di2)
      })
    } catch (e) {
      errorService(e)
    }
  }

  async function report(item) {
    modalStore.showConfirm({
      title: t.translate('words.cetak'),
      children: t.translate('words.confirmReport'),
      onSubmit: (callback) => {
        var selected = JSON.parse(TableWrapper.getSelected(baseId))
        var selectedData = []
        selected.map((d) => selectedData.push(data[d]))
        if (selectedData.length == 1) {
          reportExc(item)
        } else {
          reportBulkExc(item)
        }
        callback()
      }
    })
  }

  // Tambahan uci
  var columns= [
    {
      label: t.translate('words.sptName'),
      searchable: true,
      sortable : true,
      key: 'sptName',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptName),
    },
    {
      label: t.translate('words.sptNpwp'),
      searchable: true,
      sortable : true,
      key: 'sptNpwp',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptNpwp),
    },
    {
      label: t.translate('words.sptAddress'),
      searchable: true,
      sortable : true,
      key: 'sptAddress',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptAddress)
    },
    {
      label: t.translate('words.sptMonth'),
      searchable: true,
      sortable : true,
      key: 'sptMonth',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptMonth),
      
    },
    {
      label: t.translate('words.sptYear'),
      searchable: true,
      sortable : true,
      key: 'sptYear',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptYear),
    },
    {
      label: t.translate('words.sptRev'),
      searchable: true,
      sortable : true,
      key: 'sptRev',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptRev),
    },
    {
      label: t.translate('words.status'),
      searchable: true,
      sortable : true,
      key: 'status',
      type: TableWrapper.dataTypes.STRING,
      render: item => {
        if(item.status == 1){
          return "Kurang Bayar"
        } else {
          return "Kurang Bayar"
        }
      }
    },
    {
      label: t.translate('words.date'),
      searchable: true,
      sortable : true,
      key: 'date',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.date)
    },
    {
      label: t.translate('words.ntte'),
      searchable: true,
      sortable : true,
      key: 'ntte',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.ntte)
    },
    {
      label: t.translate('words.nominal'),
      searchable: true,
      sortable : true,
      key: 'nominal',
      type: TableWrapper.dataTypes.STRING,
      render: item => (format(item.nominal))
    },
    {
      label: t.translate('words.sptIdDjp'),
      searchable: true,
      sortable : true,
      key: 'sptIdDjp',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.sptIdDjp)
    },
    {
      label: t.translate('words.createdBy'),
      searchable: true,
      sortable : true,
      key: 'createdBy',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.createdBy)
    },
    {
      label: t.translate('words.createdDate'),
      searchable: true,
      sortable : true,
      key: 'createdDate',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.createdDate)
    },
    {
      label: t.translate('words.lastModifiedBy'),
      searchable: true,
      sortable : true,
      key: 'lastModifiedBy',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.lastModifiedBy)
    },
    {
      label: t.translate('words.lastModifiedDate'),
      searchable: true,
      sortable : true,
      key: 'lastModifiedDate',
      type: TableWrapper.dataTypes.STRING,
      render: item => (item.lastModifiedDate)
    },
  ]

  if(dalamSpt){
    columns= [
      {
        label: t.translate('words.sptAddress'),
        searchable: true,
        sortable : true,
        key: 'sptAddress',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.sptAddress)
      },
      {
        label: t.translate('words.status'),
        searchable: true,
        sortable : true,
        key: 'status',
        type: TableWrapper.dataTypes.STRING,
        render: item => {
          if(item.status == 1){
            return "Kurang Bayar"
          } else {
            return "Kurang Bayar"
          }
        }
      },
      {
        label: t.translate('words.date'),
        searchable: true,
        sortable : true,
        key: 'date',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.date)
      },
      {
        label: t.translate('words.ntte'),
        searchable: true,
        sortable : true,
        key: 'ntte',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.ntte)
      },
      {
        label: t.translate('words.nominal'),
        searchable: true,
        sortable : true,
        key: 'nominal',
        type: TableWrapper.dataTypes.STRING,
        render: item => (format(item.nominal))
      },
      {
        label: t.translate('words.sptIdDjp'),
        searchable: true,
        sortable : true,
        key: 'sptIdDjp',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.sptIdDjp)
      },
      {
        label: t.translate('words.createdBy'),
        searchable: true,
        sortable : true,
        key: 'createdBy',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.createdBy)
      },
      {
        label: t.translate('words.createdDate'),
        searchable: true,
        sortable : true,
        key: 'createdDate',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.createdDate)
      },
      {
        label: t.translate('words.lastModifiedBy'),
        searchable: true,
        sortable : true,
        key: 'lastModifiedBy',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.lastModifiedBy)
      },
      {
        label: t.translate('words.lastModifiedDate'),
        searchable: true,
        sortable : true,
        key: 'lastModifiedDate',
        type: TableWrapper.dataTypes.STRING,
        render: item => (item.lastModifiedDate)
      },
    ]
  }
  // Tambahan uci

  return (
    <>
      <LoadingOverlay
        style={{
          position: 'inherit',
          background: 'red'
        }}
        active={ploading.loading}
        spinner
        text={ploading.message}
        >
      </LoadingOverlay>
      <TableWrapper useFilter={false}
        baseId={baseId}
        title={t.translate('modules.daftar-bpe.title')}
        className={className}
        selectable={true}
        showActionColumn = {false}
        defaultSortBy="createdDate"
        setPage={search.page} onFetchData={(params)=> {
          return (
            new Promise(async (resolve, reject)=> {
              params = {...params,...search}
              try {
                if(AppMode.onPremise){
                  if(params['npwpProfile.in'] && params['npwpProfile.in'].length > 0){
                    var stringIn = ""
                    params['npwpProfile.in'].map((d)=> {
                      stringIn += d.value.npwp + ","
                    })
                    params['npwp.in'] = stringIn
                  }  else {
                    delete params['npwp.in']
                  }
                } else {
                  if(params['npwpProfile.in'] && params['npwpProfile.in'].length > 0){
                    var stringIn = ""
                    params['npwpProfile.in'].map((d)=> {
                      stringIn += d.value.npwp + ","
                    })
                    params['npwp.in'] = stringIn
                  }  else {
                    delete params['npwp.in']
                  }
                }
              } catch(e){}
              try {
                let getPage = null
                if(window.location.href.indexOf('open') != -1){
                  var spt = JSON.parse(localStorage.getItem('spt'))
                  params.sptNpwp = spt.npwp
                  params.sptYear = spt.year
                  params.sptMonth= spt.month
                  params.sptRev  = spt.rev
                  delete params['npwpProfile.in']
                  getPage = await service.getBySpt(params)
                } else {
                  delete params['npwpProfile.in']
                  getPage = await service.get(params)
                }
                delete search.page; setSearch(search); getPage.headers['x-pagination-count'] = getPage.headers['x-total-count']
                setData(getPage.data)
                resolve(getPage)
              } catch(e){
                resolve({ data: [] })
                errorService(e)
              }
            })
          )
        }}
        sideHeader={
          <DataForm 
            baseId={`${baseId}-search`}
            defaultData={search}
            submitIconClassName = 'mdi mdi-magnify'
            submitLabel={`${t.translate('words.cari')}`}
            additionalAction={[
              {
                show: true,
                render: ()=> (
                  <Button style={{ marginRight: 15 }} onClick={()=> {
                    setSearch({})
                    var newsearch = { page: 0 }; setSearch(newsearch); localStorage.removeItem(`${baseId}-saved-search`); localStorage.removeItem(`${baseId}-query`);
                    setTimeout(()=> {
                      document.getElementsByClassName('mdi-reload')[0].click()
                    }, 1000)
                  }}>{t.translate('words.hapus')}</Button>
                )
              }
            ]}
            onSubmit={(values, callback)=> {
              values.search = true
              if(values['createdDate.greaterOrEqualThan']) values['createdDate.greaterOrEqualThan'] = new Date(values['createdDate.greaterOrEqualThan'])
              if(values['createdDate.lessOrEqualThan']) values['createdDate.lessOrEqualThan'] = new Date(values['createdDate.lessOrEqualThan'])
              if(AppMode.onPremise){
                if(values['npwpProfile.in'] && values['npwpProfile.in'].length > 0){
                  var stringIn = ""
                  values['npwpProfile.in'].map((d)=> {
                    stringIn += d.value + ","
                  })
                  values['npwp.in'] = stringIn
                }  else {
                  delete values['npwp.in']
                }
              } else {
                if(values['npwpProfile.in'] && values['npwpProfile.in'].length > 0){
                  var stringIn = ""
                  values['npwpProfile.in'].map((d)=> {
                    stringIn += d.value.npwp + ","
                  })
                  values['npwp.in'] = stringIn
                }  else {
                  delete values['npwp.in']
                }
                if(values.allNpwps){
                  var allNpwpString = ""
                  if(organization && organization.length > 0){
                    organization.map((d)=> {
                      allNpwpString += d.value.npwp + ","
                    })
                  }
                  values['npwp.in'] = allNpwpString
                  delete values.allNpwps
                }
              }
              
              values.page = 0;setSearch(values);localStorage.setItem(`${baseId}-saved-search`, JSON.stringify(values))
              setTimeout(()=> {
                document.getElementsByClassName('mdi-reload')[0].click()
                callback("", false, false)
              }, 1000)
            }}
            definitions={searchDef}
          />
        }
        columns={columns}
        actions={[
          new TableWrapper.action(`${t.translate('words.requestCetak')}`, 'mdi mdi-file', (item) => request(item), true),
          new TableWrapper.action(`${t.translate('words.cetak')}`, 'mdi mdi-file-pdf', (item) => report(item), true)
        ]}
        itemActions={[
          // new TableWrapper.action(`${t.translate('words.Edit')}`, 'mdi mdi-pencil', (item) => history.push(`${basePath}/${item.id}`), true),
        ]}
        showCommandbar={showCommandbar}
        showFilterPeriod={false}
      />
    </>
  )
}

export default inject('temporaryStore','authStore', 'modalStore')(observer(DaftarBpe))
